import React from "react";
import "./App.scss";
import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { Layout } from "./components/_common/Layout";
import { ThemeProvider } from "@emotion/react";
import { Home } from "./pages/Home";
import { Portfolio } from "./pages/Portfolio";
import { muiTheme } from "./theme/create-components";
import { DemoLogin } from "./pages/DemoLogin";
import { SignIn } from "./pages/SignIn";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Signup } from "./pages/Signup";

function PrivateRoute() {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  if (!isAuthenticated) {
    localStorage.removeItem("isAuthenticated");

    return <Navigate to="/demo-login" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}
function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <div className="App">
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              <Route path="/home" element={<Home />} />
              <Route path="/portfolio" element={<Portfolio />} />
            </Route>
          </Route>

          <Route>
            <Route path="/demo-login" element={<DemoLogin />} />
            <Route path="/" element={<Navigate to="/demo-login" />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
