import React from "react";
import { LangToggle } from "./LangToggle";
import { Theme, useMediaQuery } from "@mui/material";

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div className="auth-wrapper">
      <div>
        <LangToggle />
      </div>
      {children}
      <div
        className="signin-img-section"
        style={{
          display: downMd ? "none" : "flex",
        }}
      ></div>
    </div>
  );
};
