import React, { Dispatch, SetStateAction } from "react";
import { Like } from "../../_common/Like";
import { Popup } from "react-map-gl";
import i18n2 from "../../../i18n";
import { useTranslation } from "react-i18next";
import { MappedPropertyPoint } from "./MapUpMd";

export const MapProperyPopup = ({
  selectedPoint,
  setSelectedPoint,
}: {
  selectedPoint: MappedPropertyPoint;
  setSelectedPoint: Dispatch<SetStateAction<MappedPropertyPoint | null>>;
}) => {
  const currentLanguage = i18n2.language;
  const isRTL = currentLanguage === "he";

  return (
    <Popup
      latitude={selectedPoint.latitude}
      longitude={selectedPoint.longitude}
      anchor="bottom"
      onClose={() => setSelectedPoint(null)}
      closeOnClick={false}
      closeButton={false}
      maxWidth="322px"
      offset={50}
    >
      <div
        className="map-card-popup-container"
        style={{
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div className="map-card-popup-container-img">
          <div>
            <img
              className="map-card-popup-container-main-img"
              src={selectedPoint.mainImg.url}
              style={{
                position: "relative",
              }}
            />

            <div
              className="map-card-popup-container-main-img-like"
              style={{
                right: isRTL ? "8px" : "auto",
                left: isRTL ? "auto" : "8px",
              }}
            >
              <Like />
            </div>
          </div>
        </div>

        <div className="map-card-popup-info-container">
          <div className="map-card-popup-info-price-and-like-container">
            <div className="map-card-popup-container-price">
              {isRTL ? "₪" : "$"}
              {selectedPoint.basePrice}
            </div>
          </div>
          <div
            className="map-card-popup-container-name"
            style={{
              letterSpacing: isRTL ? "0.3px" : "",
            }}
          >
            {selectedPoint.title}
          </div>
          <div
            className="map-card-popup-container-location"
            style={{
              letterSpacing: isRTL ? "0.3px" : "",
            }}
          >
            {selectedPoint.location.address}, {selectedPoint.location.city}
          </div>
        </div>
      </div>
    </Popup>
  );
};
