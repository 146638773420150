import React from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ReactComponent as SelectArrow } from "../../assets/icons/select_arrow_black.svg";
import i18n from "../../i18n";
import { useAppDispatch } from "../../store/store";
import { setActivePropertiesFilter } from "../../store/features/portfolioSlice";
import {
  allLocations,
  allPropertyTypes,
  allStatuses,
  locations,
  priceRanges,
  propertyTypes,
  statuses,
} from "../../constants/propertyData";
import {
  FilterSelectProps,
  FilterSideBarSelectProps,
  IOptionWithText,
  OptionType,
} from "../../types/properties";
import { renderValueWithTrancateLength } from "../../helpers/dataConverts";
import { useTranslation } from "react-i18next";
import { ALL } from "../_common/CustomFilterSelect";

export const FilterSideBarSelect = <T extends OptionType>({
  options,
  fontFamily,
  truncateLength,
  clearTrigger,
  selectedOptions = [],
  onChange,
}: FilterSideBarSelectProps<T>) => {
  const { t } = useTranslation(["home"]);
  const dispatch = useAppDispatch();

  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  React.useEffect(() => {
    if (clearTrigger > 0) {
      dispatch(
        setActivePropertiesFilter({
          // location: allLocations,
          // propertyTypes: allPropertyTypes,
          // priceRange: [0, 100_000_000],
          // statuses: allStatuses,
        })
      );
    }
  }, [clearTrigger]);

  const handleChangeOption = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];

    if (value.includes(ALL)) {
      const allIds = optionsWithText.map((item) => item.id.toString());
      let eq = false;

      if (allIds.length == selectedOptions.length) {
        eq = true;
        for (let i = 0; i < allIds.length; i++) {
          if (allIds[i] != selectedOptions[i]) {
            eq = false;
            break;
          }
        }
      }

      if (eq) {
        onChange([]);
      } else {
        onChange(allIds);
      }
    } else {
      onChange(value);
    }
  };

  const optionsWithText = Object.keys(options).map((key) => {
    const option = options[Number(key)];
    return { id: option.id, text: isRTL ? option.valueHe : option.valueEn };
  });

  return (
    <Select
      multiple
      value={selectedOptions || []}
      onChange={handleChangeOption}
      renderValue={(selected) =>
        renderValueWithTrancateLength(selected, truncateLength, options, isRTL)
      }
      fullWidth
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(255, 255, 255, 1)",
            },
          },
        },
      }}
      sx={{
        color: "rgba(0, 0, 0, 1)",
        borderRadius: "16px",
        fontFamily: fontFamily,
        fontWeight: 400,
        fontSize: "14px",
        height: "50px",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid rgba(224, 229, 242, 1)",
        },
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          paddingRight: isRTL ? "20px" : "20px",
          paddingLeft: isRTL ? "20px" : "20px",
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(129, 154, 221, 1)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(129, 154, 221, 1)",
        },
      }}
      IconComponent={SelectArrow}
    >
      <MenuItem
        value={ALL}
        sx={{
          color: "rgba(0, 0, 0, 1)",
          fontFamily: fontFamily,
          fontWeight: 500,
          fontSize: "14px",
          direction: isRTL ? "rtl" : "ltr",
          "&:hover": {
            backgroundColor: "#acc3d954",
          },
        }}
      >
        {t("all")}
      </MenuItem>
      {optionsWithText.map((option: IOptionWithText) => (
        <MenuItem
          value={option.id.toString()}
          key={option.id}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: fontFamily,
            fontWeight: 500,
            fontSize: "14px",
            direction: isRTL ? "rtl" : "ltr",
            "&:hover": {
              backgroundColor: "#acc3d954",
            },
          }}
        >
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
