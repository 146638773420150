import React from "react";
import { useAppDispatch } from "../../store/store";
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { setOpenSideNav } from "../../store/features/common";
import Burger_Menu from "../../assets/icons/burger_menu.svg";
import logo from "../../assets/logo.png";
import Arrow from "../../assets/icons/arrow.svg";
import { LangToggle } from "./LangToggle";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TOP_NAV_HEIGHTUpMd = 106;
const TOP_NAV_HEIGHTDownMd = 81;

export const Header = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["navbar"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const dispatch = useAppDispatch();

  const handleNavigateToSignIn = () => {
    navigate("/signin");
  };
  return (
    <Box
      component="header"
      sx={{
        borderBottom: "1px solid #00000017",
        mx: "20px",
      }}
      className="xl-width"
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          minHeight: downMd ? TOP_NAV_HEIGHTDownMd : TOP_NAV_HEIGHTUpMd,
        }}
      >
        {
          <img
            src={logo}
            style={{
              marginLeft: "20px",
              width: downMd ? "97px" : downLg ? "97px" : "132px",
            }}
          />
        }
        <Box display={"flex"} alignItems={"center"}>
          {downMd && (
            <img
              src={Burger_Menu}
              onClick={() => dispatch(setOpenSideNav())}
              style={{ marginRight: "20px", cursor: "pointer" }}
            />
          )}
        </Box>

        {!downMd && (
          <Box display={"flex"} justifyContent={"space-between"} mt={"5px"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: 12,
              }}
            >
              {children}
            </Box>
          </Box>
        )}

        {!downMd && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: downLg ? "15px" : "25px",
            }}
          >
            <LangToggle />
            <Typography
              onClick={handleNavigateToSignIn}
              sx={{
                fontSize: downLg ? "14px" : "16px",
                color: "#000",
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 400 : 500,
                cursor: "pointer",
              }}
            >
              {t("sign_in")}
            </Typography>
            <Box
              sx={{
                mr: "20px",
                display: "flex",
                alignItems: "center",
                color: "#fff",
                backgroundColor: "#034A73",
                p: downLg ? "15px 25px 15px 25px" : "15px 33px 15px 33px",
                cursor: "pointer",
                borderRadius: "24px",
                transition: "background 2s ease-in-out .5s",
                "&:hover": {
                  background: "linear-gradient(to right, #034A73, #0A7992)",
                },

                flexDirection: isRTL ? "row-reverse" : "row",
              }}
            >
              <Typography
                sx={{
                  mr: isRTL ? "0" : "12px",
                  ml: isRTL ? "12px" : "0",

                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  lineHeight: "16px",
                  fontSize: downLg ? "14px" : "16px",
                }}
              >
                {t("contact_us")}
              </Typography>
              <img
                src={Arrow}
                alt="arrow"
                style={{
                  transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
                }}
              />
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
