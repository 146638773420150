import React from "react";
import { useTranslation } from "react-i18next";
import { LangToggle } from "../components/_common/LangToggle";
import { Checkbox, IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "../assets/icons/visibility.svg";
import VisibilityOff from "../assets/icons/visibility_off.svg";
import { useNavigate } from "react-router-dom";
import { AuthWrapper } from "../components/_common/AuthWrapper";
import { SignWithGoogleBtn } from "../components/forgotPassword/SignWithGoogleBtn";
import { ORBlock } from "../components/forgotPassword/ORBlock";
import { authTextFieldStyles } from "./Signup";

export const SignIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };

  return (
    <AuthWrapper>
      <div className="signin-form-section">
        <div
          className="signin-form-container"
          style={{
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <div
            className="signin-form-title-container"
            style={{
              direction: isRTL ? "rtl" : "ltr",
            }}
          >
            <div
              className="signin-form-title-signin"
              style={{
                fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
                textTransform: "capitalize",
              }}
            >
              {t("sign_in")}
            </div>
            <div
              className="signin-vertical-divider"
              style={{
                marginRight: isRTL ? "12px" : "16px",
                marginLeft: isRTL ? "12px" : "20px",
              }}
            ></div>
            <div
              className="signin-form-title-welcom"
              style={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
              }}
            >
              {t("welcome_back")}
            </div>
          </div>
          <SignWithGoogleBtn />
          <ORBlock />

          <div className="signin-form-inputs-container">
            <div
              className="signin-form-inputs-label"
              style={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
              }}
            >
              {t("email")}
            </div>
            <TextField
              type="email"
              fullWidth
              required
              placeholder={t("email_placeholder")}
              sx={(theme) => authTextFieldStyles.textField(theme, isRTL)}
            />
            <div
              className="signin-form-inputs-label"
              style={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                marginTop: "15px",
              }}
            >
              {t("password")}
            </div>
            <TextField
              type={showPassword ? "text" : "password"}
              fullWidth
              required
              placeholder={t("password_placeholder")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <img
                          src={VisibilityOff}
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                      ) : (
                        <img
                          src={Visibility}
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={(theme) => authTextFieldStyles.textField(theme, isRTL)}
            />
            <div className="signin-form-k-m-l-in-and-g-p-container">
              <div className="signin-form-keep-me-logged-in-container">
                <Checkbox
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fill: "rgba(43, 54, 116, 1)",
                    },
                    "&.Mui-checked .MuiSvgIcon-root": {
                      fill: "rgba(3, 74, 115, 1)",
                    },
                    "&.Mui-disabled .MuiSvgIcon-root": {
                      fill: "rgba(200, 200, 200, 1)",
                    },
                  }}
                />
                <div
                  style={{
                    fontFamily: isRTL ? "Alef" : "DM Sans",
                    fontWeight: isRTL ? 400 : 500,
                    color: "rgba(43, 54, 116, 1)",
                    fontSize: "14px",
                    letterSpacing: "-0.32px",
                    lineHeight: "20px",
                  }}
                >
                  {t("keep_me_logged_in")}
                </div>
              </div>
              <div
                onClick={() => handleForgotPassword()}
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  color: "rgba(215, 161, 72, 1)",
                  fontSize: "14px",
                  letterSpacing: "-0.32px",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              >
                {t("forget_password")}
              </div>
            </div>
            <button
              className="signin-form-inputs-signin-btn"
              style={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 700 : 500,
              }}
            >
              {t("sign_in")}
            </button>
            <div className="not-registred-yet-container">
              <div
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  color: "rgba(43, 54, 116, 1)",
                  fontSize: "14px",
                  letterSpacing: "-0.32px",
                  lineHeight: "26px",
                }}
              >
                {t("not_registered_yet")}
              </div>
              <div
                onClick={() => navigate("/signup")}
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: isRTL ? 700 : 500,
                  color: "rgba(215, 161, 72, 1)",
                  fontSize: "14px",
                  letterSpacing: "-0.32px",
                  lineHeight: "26px",
                  cursor: "pointer",
                }}
              >
                {t("create_an_account")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};
