import React from "react";
import { Header } from "../components/_common/Header";
import { Navbar } from "../components/_common/Navbar";
import { Theme, useMediaQuery } from "@mui/material";
import { PortfolioHeroImg } from "../components/portfolio/PortfolioHeroImg";
import { useTranslation } from "react-i18next";
import { PropertiesContainer } from "../components/portfolio/PropertiesContainer";
import { convertToProperties, Property } from "../helpers/convert";
import {
  useGetPropertiesQuery,
  useGetPropertiesTestQuery,
  useLazyGetPropertiesTestQuery,
} from "../store/api/propertyApiSlice";
import { PREFERED_PROPERTY_NUM } from "./Home";
import { getRandomProperty } from "../helpers/fakeProperties";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { Footer } from "../components/_common/Footer";
import { FooterSocialMediaLine } from "../components/_common/FooterSocialMediaLine";
import { useAppDispatch } from "../store/store";
import { FixedMenu } from "../components/portfolio/FixedMenu";
import {
  activePropertyFiltersSelector,
  fetchedPropertiesSelector,
  propertiesPaginationParamnsSelector,
  setFetchedProperties,
  setPropertyError,
  setPropertiesPaginationParams,
  setActivePropertiesFilter,
} from "../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  convertLocations,
  getAddParamByNameSortParam,
  getPriceRangeBounds,
  mapIdsToValues,
} from "../helpers/dataConverts";
import {
  allLocations,
  allPropertyTypes,
  allStatuses,
  locations,
  priceRanges,
  propertyTypes,
  statuses,
} from "../constants/propertyData";
import { IPropertySearchParamObj } from "../types/properties";
import { randomMediaGenerator } from "../helpers/fakeMedia";

export const Portfolio = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const currentLanguage = i18n.language;

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const activePropertyFilters = useSelector(activePropertyFiltersSelector);
  const paginationParams = useSelector(propertiesPaginationParamnsSelector);
  const fetchedPropertiesSel = useSelector(fetchedPropertiesSelector);

  const [page, setPage] = React.useState(1);

  const onPageChange = (pageNum: number) => {
    setPage(pageNum);
  };

  const [
    trigger,
    { data: fetchedProperties, error: fetchError, isError, isLoading },
  ] = useLazyGetPropertiesTestQuery();

  // --------------------------------
  const [searchParams] = useSearchParams();

  const sortParam = searchParams.get("sort") || "created_at";
  const sortDirection = searchParams.get("direction") || "asc";

  React.useEffect(() => {
    const paramsObject: Partial<IPropertySearchParamObj> = {};
    let found = false;

    searchParams.forEach((value, key) => {
      if (!paramsObject[key as keyof IPropertySearchParamObj]) {
        paramsObject[key as keyof IPropertySearchParamObj] = [];
      }
      if (!["page", "sort", "direction"].includes(key)) {
        found = true;
      }
      (paramsObject[key as keyof IPropertySearchParamObj] as string[]).push(
        value
      );
    });
    if (searchParams.size === 3) {
      if (!found) {
        dispatch(
          setActivePropertiesFilter({
            // location: allLocations,
            // propertyTypes: allPropertyTypes,
            priceRange: [0, 100_000_000],
            // statuses: allStatuses,
          })
        );
      }
    } else {
      const paramsObject: Partial<IPropertySearchParamObj> = {};

      searchParams.forEach((value, key) => {
        if (!paramsObject[key as keyof IPropertySearchParamObj]) {
          paramsObject[key as keyof IPropertySearchParamObj] = [];
        }
        (paramsObject[key as keyof IPropertySearchParamObj] as string[]).push(
          value
        );
      });

      dispatch(setActivePropertiesFilter(paramsObject));
    }
  }, [searchParams]);
  // --------------------------------

  React.useEffect(() => {
    if (isError && fetchError) {
      dispatch(setPropertyError({ propertyError: fetchError }));
      return;
    }

    if (fetchedProperties?.data) {
      const props = convertToProperties(
        randomMediaGenerator,
        fetchedProperties.data
      );
      dispatch(setFetchedProperties(props));
      dispatch(setPropertiesPaginationParams(fetchedProperties.meta));
    }
  }, [fetchedProperties, isError, fetchError]);

  const [isFiltersReady, setIsFiltersReady] = React.useState(false);

  React.useEffect(() => {
    const filtersAreReady = !Object.values(activePropertyFilters).some(
      (filter) => filter.length === 0
    );
    setIsFiltersReady(filtersAreReady);
  }, [activePropertyFilters]);

  React.useEffect(() => {
    if (!isFiltersReady) return;

    const params = new URLSearchParams();
    Object.entries(activePropertyFilters).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((value) => params.append(key, value));
      }
    });

    params.set("page", paginationParams.pagination.page.toString());
    params.set("sort", sortParam);
    params.set("direction", sortDirection);

    navigate(`/portfolio?${params.toString()}`, { replace: true });

    dispatch(setPropertyError({ propertyError: "" }));

    let lte = 0;
    let gte = 100_000_000;
    let range = activePropertyFilters?.priceRange;

    if (range) {
      if (range.length > 0) {
        lte = range[0];
      }
      if (range.length > 1) {
        gte = range[1];
      }
    }

    const filters = {
      $or: convertLocations(activePropertyFilters.location, locations),
      pricing: {
        basePrice: {
          $gte: lte,
          $lte: gte,
        },
      },
      propertyStatus: mapIdsToValues(
        activePropertyFilters.statuses,
        statuses,
        isRTL
      ),
      propertyType: mapIdsToValues(
        activePropertyFilters.propertyTypes,
        propertyTypes,
        isRTL
      ),
    };

    trigger({
      locale: currentLanguage,
      filters: filters,
      sort: {
        sortParam: [sortParam, getAddParamByNameSortParam(sortParam)],
        sortDirection: sortDirection,
        addParam: getAddParamByNameSortParam(sortParam),
      },
      pagination: { pageSize: 10, page: page },
      name: searchParams.get("name"),
    });
  }, [
    isFiltersReady,
    activePropertyFilters,
    page,
    sortParam,
    sortDirection,
    currentLanguage,
  ]);

  return (
    <div
      style={{
        letterSpacing: isRTL ? "-1.3px" : "",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <>
        <Header>
          <Navbar />
        </Header>

        {downMd && <FixedMenu />}
        <PortfolioHeroImg />
        <PropertiesContainer
          allProperties={fetchedPropertiesSel}
          onPageChange={onPageChange}
          isLoading={isLoading}
        />
        <GetInTouchBlock />
        <Footer />
        <FooterSocialMediaLine />
      </>
    </div>
  );
};
