import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Property } from "../../helpers/convert";
import { Pagination, Theme, useMediaQuery } from "@mui/material";
import { PropertiesSortSelect } from "./PropertiesSortSelect";
import { PropertyCard } from "./PropertyCard";
import { useSelector } from "react-redux";
import {
  mapCheckedSelector,
  propertiesPaginationParamnsSelector,
  propertyErrorSelector,
} from "../../store/features/portfolioSlice";
import { MapUpMd } from "./map/MapUpMd";
import { PropertiesPagination } from "./PropertiesPagination";
import CircularProgress from "@mui/material/CircularProgress";
import { allPropertyTypes, sortOptions } from "../../constants/propertyData";
import { MapSideBar } from "./map/MapSideBar";
import { filterPropertiesByIds } from "../../helpers/dataConverts";

export const PropertiesContainer = ({
  allProperties,
  onPageChange,
  isLoading,
}: {
  allProperties: Property[];
  onPageChange: (pageNum: number) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation(["portfolio"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downXl = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const upXl = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const paginationParams = useSelector(propertiesPaginationParamnsSelector);
  const [visibleMarkerIds, setVisibleMarkerIds] = React.useState<number[]>([]);

  const mapChecked = useSelector(mapCheckedSelector);
  const propertyError = useSelector(propertyErrorSelector);

  const visibleProperties = useMemo(() => {
    if (!mapChecked) {
      return allProperties;
    }
    if (visibleMarkerIds.length === 0) {
      return allProperties;
    }
    return filterPropertiesByIds(allProperties, visibleMarkerIds);
  }, [allProperties, visibleMarkerIds, mapChecked]);

  // console.log("log: ", downXl);

  // const upXl = useMediaQuery({ minWidth: 1280 });
  // const downXl = useMediaQuery({ maxWidth: 1280 });
  // const downMd = useMediaQuery({ maxWidth: 768 });
  return (
    <div
      // className="xl-width"
      style={{
        boxSizing: "border-box",
        paddingRight: "20px",
        paddingLeft: "20px",
        marginTop: downMd ? "30px" : "40px",
        maxWidth: "3000px",
        width: "100%",
      }}
    >
      <div>
        <div
          className="properties-top-info-container"
          style={{
            direction: isRTL ? "rtl" : "ltr",
            display: "flex",
            flexDirection: downMd ? "column" : "row",
            alignItems: downMd ? "flex-start" : "center",
            gap: downMd ? "20px" : "",
            marginBottom: downMd ? "35px" : "40px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontFamily: isRTL ? "Alef" : "DM Sans",
              fontWeight: isRTL ? 700 : 600,
              fontSize: downMd ? "16px" : "22px",
            }}
          >
            {/* {paginationParams.pagination.total} */}
            {visibleProperties.length}
            <span>&nbsp;</span>
            {t("properties_for_sale")}
          </div>
          <PropertiesSortSelect options={sortOptions} />
        </div>

        {propertyError && (
          <div
            className="xl-width"
            style={{
              display: "flex",
              justifyContent: "center",
              boxSizing: "border-box",
              paddingRight: "20px",
              paddingLeft: "20px",
              marginTop: downMd ? "30px" : "40px",
              marginBottom: downMd ? "30px" : "40px",
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            Something went wrong
          </div>
        )}

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <CircularProgress sx={{ color: "rgb(3, 74, 115)" }} />
          </div>
        ) : (
          // <div
          //   className="properties-card-container"
          //   style={{
          //     display: "grid",
          //     gap: "20px",
          //     gridTemplateColumns: mapChecked && !downMd ? "1fr 0.8fr" : "1fr",
          //   }}
          // >
          //   <div
          //     style={{
          //       display: "grid",
          //       gridTemplateColumns: `repeat(auto-fit, minmax(200px, ${
          //         upMd ? "400px" : "340px"
          //       }))`,
          //       justifyContent: "flex-start",
          //       gap: "30px",
          //       marginRight:
          //         mapChecked && !downMd
          //           ? "20px"
          //           : downMd && mapChecked
          //           ? "50px"
          //           : "0",
          //     }}
          //   >
          //     {visibleProperties.map((property: Property, index: number) => (
          //       <div
          //         key={index}
          //         className="property-card-container"
          //         style={{
          //           width: "100%",
          //           maxWidth: upMd ? "400px" : "340px",
          //           flexShrink: 0,
          //         }}
          //       >
          //         <PropertyCard property={property} key={index} />
          //       </div>
          //     ))}
          //   </div>

          //   {!downMd && mapChecked && (
          //     <MapUpMd
          //       properties={allProperties}
          //       setVisibleMarkerIds={setVisibleMarkerIds}
          //     />
          //   )}

          //   <MapSideBar properties={allProperties} />
          // </div>

          <div
            className="properties-card-container"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fit, minmax(${
                  upXl && mapChecked ? "340px" : downXl ? "340px" : "320px"
                }, 0fr))`,
                flexGrow: 1,
                gap: "30px",
                flex: 1,
              }}
            >
              {visibleProperties.map((property: Property, index: number) => (
                <div
                  key={index}
                  className="property-card-container"
                  style={{
                    maxWidth: "425px",
                  }}
                >
                  <PropertyCard property={property} />
                </div>
              ))}
            </div>

            {!downMd && mapChecked && (
              <div
                // style={{ flexBasis: downMd ? "50%" : "40%" }}
                style={{ flexBasis: "40%" }}
              >
                <MapUpMd
                  properties={allProperties}
                  setVisibleMarkerIds={setVisibleMarkerIds}
                />
              </div>
            )}

            <MapSideBar properties={allProperties} />
          </div>
        )}
      </div>

      <div
        style={{
          marginTop: "65px",
          marginBottom: "81px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <PropertiesPagination onPageChange={onPageChange} />
      </div>
    </div>
  );
};
