import React from "react";
import {
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ReactComponent as SelectArrow } from "../../assets/icons/select_arrow_black.svg";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export interface ISortOption {
  id: number;
  valueEn: string;
  valueHe: string;
  sortDirection: string;
  nameSortParam: string;
}

export const PropertiesSortSelect = ({
  options,
}: {
  options: ISortOption[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const { t } = useTranslation(["portfolio"]);

  const sortParam = searchParams.get("sort");
  const directionParam = searchParams.get("direction");

  const initialSelectedOption = React.useMemo(() => {
    if (sortParam && directionParam) {
      const matchedOption = options.find(
        (option) =>
          option.nameSortParam === sortParam &&
          option.sortDirection === directionParam
      );
      return matchedOption || options[0];
    }
    return options[0];
  }, [sortParam, directionParam, options]);

  const [selectedOption, setSelectedOption] = React.useState<ISortOption>(
    initialSelectedOption
  );

  React.useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleChangeOption = (event: SelectChangeEvent<string>) => {
    const selected = options.find(
      (option) => option.id.toString() === event.target.value
    );

    if (!selected) {
      return;
    }

    searchParams.set("sort", selected.nameSortParam);
    searchParams.set("direction", selected.sortDirection);

    setSearchParams(searchParams);

    if (selected) setSelectedOption(selected);
  };

  return (
    <Select
      fullWidth
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: "rgba(217, 228, 232, 1)",
            },
          },
        },
      }}
      sx={{
        direction: isRTL ? "rtl" : "ltr",
        backgroundColor: "rgba(3, 74, 115, 0.05)",
        border: "1px solid rgba(217, 228, 232, 1)",
        borderRadius: "30px",
        width: "231px",
        fontWeight: 500,
        color: "rgba(0, 0, 0, 1)",
        fontFamily: isRTL ? "Alef" : "DM Sans",
        height: "42px",
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "20px",
          left: isRTL ? "20px" : "auto",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(217, 228, 232, 1)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(217, 228, 232, 1)",
        },
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
          {
            paddingRight: isRTL ? 0 : "",
            paddingLeft: isRTL ? "10px" : "0",
          },
        "& .MuiOutlinedInput-input": {
          paddingRight: 0,
        },
      }}
      input={
        <OutlinedInput
          sx={{
            padding: 0,
          }}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                mr: isRTL ? "0" : "5px",
                ml: isRTL ? "5px" : "0",
              }}
            >
              <div
                style={{
                  color: "rgba(0, 0, 0, 1)",
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontSize: "16px",
                  paddingLeft: isRTL ? 0 : "10px",
                  paddingRight: isRTL ? "10px" : "0px",
                  fontWeight: 500,
                }}
              >
                {t("sort")}:
              </div>
            </InputAdornment>
          }
        />
      }
      value={selectedOption.id.toString()}
      onChange={handleChangeOption}
      IconComponent={SelectArrow}
    >
      {options.map((option: ISortOption, index: number) => (
        <MenuItem
          value={option.id.toString()}
          key={option.id}
          sx={{
            color: "rgba(0, 0, 0, 1)",
            fontFamily: isRTL ? "Alef" : "DM Sans",
            fontWeight: 500,
            fontSize: "14px",
            direction: isRTL ? "rtl" : "ltr",
            "&:hover": {
              backgroundColor: "#acc3d954",
            },
          }}
        >
          {isRTL ? option.valueHe : option.valueEn}
        </MenuItem>
      ))}
    </Select>
  );
};
