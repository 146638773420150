import React from "react";
import FilledHeartIcon from "../../assets/icons/filled_heart.svg";
import OutlinedHeartIcon from "../../assets/icons/outline_heart.svg";

export const Like = () => {
  const [clickedLike, setClickedLike] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const handleChangeClicked = () => {
    setClickedLike(!clickedLike);
  };

  return (
    <div
      className="featured-properties-like"
      onClick={handleChangeClicked}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={clickedLike || isHovered ? FilledHeartIcon : OutlinedHeartIcon}
        alt="Like Button"
        className={`heart-icon ${
          clickedLike || isHovered ? "fade-in" : "fade-out"
        }`}
      />
    </div>
  );
};
