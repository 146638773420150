import { sortOptions } from "../constants/propertyData";
import { IPriceRanges, OptionType } from "../types/properties";
import { Property } from "./convert";

export const getPriceRangeBounds = (
  selectedPriceRangesId: string[] | undefined,
  priceRanges: { [key: number]: IPriceRanges }
) => {
  if (!selectedPriceRangesId) {
    return [];
  }
  const selectedRanges = selectedPriceRangesId
    .map((id: string) => priceRanges[Number(id)])
    .filter((range): range is IPriceRanges => range !== undefined);

  if (selectedRanges.length === 0) {
    return [];
  }

  const min = Math.min(
    ...selectedRanges.map((range: IPriceRanges) => range.min)
  );
  const max = Math.max(
    ...selectedRanges.map((range: IPriceRanges) => range.max)
  );

  return {
    basePrice: {
      $lte: max,
      $gte: min,
    },
  };
};

export const convertLocations = (
  seletedLocationIds: string[],
  locations: Record<number, OptionType>
) => {
  if (!seletedLocationIds) return [];

  return seletedLocationIds.map((id: string) => {
    const location = locations[Number(id)];
    return { location: { city: location ? location.valueEn : undefined } };
  });
};

export const getAddParamByNameSortParam = (nameSortParam: string) => {
  const option = sortOptions.find(
    (option) => option.nameSortParam === nameSortParam
  );

  return option ? option.addParam : "";
};

export const mapIdsToValues = <T extends Record<number, OptionType>>(
  ids: string[],
  options: T,
  isRTL: boolean
): string[] => {
  if (!Array.isArray(ids) || ids.length === 0) {
    return [];
  }

  return ids.map((id) => {
    const option = options[Number(id)];
    return isRTL ? option.valueHe : option.valueEn;
  });
};

export const renderValueWithTrancateLength = <T extends OptionType>(
  selected: string[],
  truncateLength: number,
  options: Record<number, T>,
  isRTL: boolean
) => {
  const displayValues = selected
    .map((id) => {
      const option = options[Number(id)];
      return option ? (isRTL ? option.valueHe : option.valueEn) : null;
    })
    .filter((val) => val !== null);

  if (displayValues.length === 0) {
    return "";
  }

  if (displayValues.length === 1) {
    const singleValue: string | null = displayValues[0];
    if (singleValue === null) {
      return "";
    }
    if (singleValue.length > truncateLength) {
      return singleValue.slice(0, truncateLength - 3) + "...";
    }
    return singleValue;
  } else {
    const firstValue: string | null = displayValues[0];
    const secondValue: string | null = displayValues[1];
    if (firstValue === null) {
      return "";
    }
    const remainingCount = displayValues.length - 1;
    const suffix = ` (+${remainingCount})`;

    if (
      secondValue &&
      firstValue.length + secondValue.length + suffix.length <= truncateLength
    ) {
      return `${firstValue}, ${secondValue}${suffix}`;
    } else if (firstValue.length + suffix.length <= truncateLength) {
      return `${firstValue}${suffix}`;
    } else {
      return (
        firstValue.slice(0, truncateLength - suffix.length - 3) + "..." + suffix
      );
    }
  }
};

export const filterPropertiesByIds = (
  properties: Property[],
  visibleMapIds: number[]
) => {
  return properties.filter((property) => visibleMapIds.includes(property.id));
};

export function formatNumber(num: number) {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
