import React from "react";
import GreyPlus from "../../../assets/icons/grey_plus.svg";
import GreyMinus from "../../../assets/icons/grey_minus.svg";
import { Divider } from "@mui/material";

export const ControlMapPanel = ({
  handleZoomIn,
  handleZoomOut,
}: {
  handleZoomIn: any;
  handleZoomOut: any;
}) => {
  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 1)",
        position: "absolute",
        top: "18px",
        right: "18px",
        width: "31px",
        height: "64px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        border: "1px solid rgba(225, 228, 241, 1)",
        padding: "10px 7px",
        zIndex: 300,
      }}
    >
      <img
        src={GreyPlus}
        style={{
          cursor: "pointer",
        }}
        onClick={() => handleZoomIn()}
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: "rgba(225, 228, 241, 1)",
        }}
      />
      <img
        src={GreyMinus}
        style={{
          cursor: "pointer",
        }}
        onClick={() => handleZoomOut()}
      />
    </div>
  );
};
