import { Box, Theme, Typography, useMediaQuery } from "@mui/material";

import Grid from "@mui/material/Grid2";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConsultationInfo } from "./ConsultationInfo";
import { Property } from "../../helpers/convert";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import Location from "../../assets/icons/location.svg";
import SwiperArrow from "../../assets/icons/swiper_arrow.svg";

import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { useTranslation } from "react-i18next";

export const PropertyTypesBlock = ({
  properties,
}: {
  properties: Property[];
}) => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";

  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const [realIndex, setRealIndex] = useState(-1);
  const [realIndexA1, setRealIndexA1] = useState(-1);
  const [realIndexA2, setRealIndexA2] = useState(-1);
  const [isA1, setIsA1] = useState(true);

  useEffect(() => {
    setRealIndex(Math.max(0, properties.length - 1));
  }, [properties]);

  useEffect(() => {
    if (swiperRef && swiperRef.autoplay) {
      swiperRef.autoplay.start();
      swiperRef.autoplay.resume();
    }
  }, [
    swiperRef?.autoplay,
    swiperRef?.autoplay?.paused,
    swiperRef?.autoplay?.running,
  ]);

  const a1Ref = useRef<boolean>(true);

  useEffect(() => {
    a1Ref.current = isA1;
  }, [isA1]);

  useEffect(() => {
    setRealIndexA1((prev) => {
      if (prev == -1) {
        return realIndex;
      }
      return prev;
    });
    setRealIndexA2((prev) => {
      if (prev == -1) {
        return realIndex;
      }
      return prev;
    });

    let isa1 = a1Ref.current;
    setIsA1(!isA1);

    if (isa1) {
      setRealIndexA2(realIndex);
    } else {
      setRealIndexA1(realIndex);
    }
  }, [realIndex]);

  const onSlideClick = useCallback(
    (el: React.MouseEvent<HTMLDivElement>, i: number) => {
      if (swiperRef) {
        if (
          swiperRef.clickedSlide?.classList?.contains("swiper-slide-active")
        ) {
          setRealIndex(swiperRef.realIndex);
          swiperRef.slideNext(1000);
        }
      }
    },
    [swiperRef]
  );

  const onSlideChange = useCallback(
    (sw: SwiperClass) => {
      if (swiperRef) {
        const n = properties.length;
        const index = swiperRef.realIndex;
        setRealIndex((index + n - 1) % n);
      }
    },
    [swiperRef, properties]
  );
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="consultation-container"
      style={{
        paddingTop: downMd ? "50px" : "62px",
        paddingBottom: downMd ? "50px" : "61px",
      }}
    >
      <Grid
        container
        spacing={downLg ? 2 : 10}
        className="xl-width"
        sx={{
          paddingLeft: "20px",
          paddingRight: "20px",
          alignItems: "center",
        }}
      >
        <Grid size={{ xs: 12, lg: 4 }}>
          <ConsultationInfo />
        </Grid>
        <Grid
          size={{ xs: 12, lg: 8 }}
          sx={{
            position: "relative",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered && (
            <div
              onClick={() => {
                if (swiperRef) {
                  swiperRef.slideNext(1000);
                }
              }}
              style={{
                zIndex: 20,
                position: "absolute",
                right: "20px",
                width: "42px",
                height: "42px",
                top: "45%",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            >
              <img src={SwiperArrow} />
            </div>
          )}
          <Box
            sx={{
              display: "flex",
            }}
          >
            <div
              style={{
                flex: downSm ? 2 : downMd ? 2 : 1,
                width: downMd ? "40vw" : "380px",
                height: downMd ? "250px" : "400px",
                position: "relative",
              }}
            >
              {properties.length > 0 &&
                realIndexA1 != -1 &&
                realIndexA2 != -1 && (
                  <>
                    <div
                      style={{
                        borderRadius: "20px",
                        width: "100%",
                        height: "100%",
                      }}
                      className={`alternate ${isA1 && "alfter-active"}`}
                    >
                      <div
                        style={{
                          borderRadius: "20px",
                          width: "100%",
                          height: "100%",
                          background: `url(${properties[realIndexA1]?.mainImage.url}) center center / cover no-repeat`,
                          objectFit: "cover",
                        }}
                      >
                        <div
                          style={{
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            gap: "5px",
                            display: "flex",
                            height: "100%",
                            borderRadius: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",

                            alignItems: isRTL ? "flex-end" : "flex-start",
                            background:
                              "linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%)",
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              color: "rgba(215, 161, 72, 1)",
                              fontFamily: isRTL ? "Alef" : "DM Sans",
                              fontWeight: 500,
                              textAlign: isRTL ? "end" : "start",
                            }}
                          >
                            {properties[realIndexA1]?.type}
                          </Typography>

                          <Typography
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
                              fontWeight: 600,
                              fontSize: downMd ? "20px" : "28px",
                              textAlign: isRTL ? "end" : "start",
                              lineHeight: "22px",
                              my: "8px",
                            }}
                          >
                            {properties[realIndexA1]?.name}
                          </Typography>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              paddingBottom: "20px",
                            }}
                          >
                            <img src={Location} />
                            <Typography
                              variant="h4"
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontFamily: isRTL ? "Alef" : "DM Sans",
                                fontWeight: 300,
                              }}
                            >
                              {properties[realIndexA1]?.location.city}
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>

                    <div
                      style={{
                        borderRadius: "20px",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      className={`alternate ${!isA1 && "alfter-active"}`}
                    >
                      <div
                        style={{
                          borderRadius: "20px",
                          width: "100%",
                          height: "100%",
                          background: `url(${properties[realIndexA2]?.mainImage.url}) center center / cover no-repeat`,
                          objectFit: "cover",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            gap: "5px",
                            display: "flex",
                            height: "100%",
                            borderRadius: "20px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            alignItems: isRTL ? "flex-end" : "flex-start",
                            background:
                              "linear-gradient(to top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%)",
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              color: "rgba(215, 161, 72, 1)",
                              fontFamily: isRTL ? "Alef" : "DM Sans",
                              fontWeight: 500,
                            }}
                          >
                            {properties[realIndexA2]?.type}
                          </Typography>

                          <Typography
                            sx={{
                              color: "rgba(255, 255, 255, 1)",
                              fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
                              fontWeight: 600,
                              fontSize: downMd ? "20px" : "28px",
                              lineHeight: "22px",
                              my: "8px",
                            }}
                          >
                            {properties[realIndexA2]?.name}
                          </Typography>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              paddingBottom: "20px",
                            }}
                          >
                            <img src={Location} />
                            <Typography
                              variant="h4"
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontFamily: isRTL ? "Alef" : "DM Sans",
                                fontWeight: 300,
                              }}
                            >
                              {properties[realIndexA2]?.location.city}
                            </Typography>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                  </>
                )}
            </div>
            <div
              style={{
                flex: 1,
                paddingLeft: "20px",
                width: downSm ? "100px" : downMd ? "200px" : "300px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Swiper
                allowTouchMove={false}
                className="p-types-slider"
                loop={true}
                spaceBetween={20}
                slidesPerView={downMd ? 1 : 2}
                onSlideChange={onSlideChange}
                onSwiper={setSwiperRef}
                speed={1000}
                autoplay={{
                  delay: 2000,
                  pauseOnMouseEnter: false,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                {properties.map((property, i) => {
                  return (
                    <SwiperSlide
                      className={`slide-${i}`}
                      key={property.id + "-" + i}
                      style={{
                        width: "210px",
                        height: downMd ? "150px" : "320px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                        }}
                      >
                        <div
                          onClick={(e) => {
                            onSlideClick(e, i);
                          }}
                          className="img"
                          style={{
                            borderRadius: "20px",
                            transition: "opacity 0.2s",
                            width: "100%",
                            height: "100%",
                            background: `url(${property?.mainImage.url}) center center / cover no-repeat`,
                            objectFit: "cover",
                          }}
                        ></div>
                      </Box>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
