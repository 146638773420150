import React from "react";
import EmailChecked from "../../assets/icons/email_checked.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackToLoginBtn } from "./BackToLoginBtn";
import { Grid, Grid2, TextField } from "@mui/material";

export const PasswordResetConfirmEmailStep = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const [code, setCode] = React.useState(["", "", "", ""]);

  const [error, setError] = React.useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newCode = [...code];
    newCode[index] = e.target.value.slice(0, 1);
    setCode(newCode);

    if (e.target.value) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        (nextInput as HTMLInputElement).focus();
      }
    }
  };

  return (
    <div className="forgot-p-container">
      <div className="forgot-p-email-checked-container">
        <img src={EmailChecked} />
      </div>
      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
        }}
      >
        {t("password_reset")}
      </div>

      <div
        className="forgot-p-send-email-desc-container"
        style={{
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div
          className="forgot-p-reset-desc"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("we_sent_code_to")}
        </div>
        <div
          className="forgot-p-send-email-desc-email"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          johndoe@gmail.com
        </div>
      </div>

      <Grid2 container spacing={2} justifyContent="center" alignItems="center">
        {code.map((_, index) => (
          <Grid item key={index}>
            <TextField
              id={`input-${index}`}
              value={code[index]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e, index)
              }
              variant="outlined"
              inputProps={{
                maxLength: 1,
                style: {
                  textAlign: "center",
                  width: "43px",
                  height: "38px",
                  fontSize: "36px",
                  color: "rgba(0, 0, 0, 1)",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                },
              }}
              sx={{
                "& .MuiInputBase-input": {
                  borderRadius: "16px",
                  border: code[index]
                    ? "1px solid rgba(129, 154, 221, 1)"
                    : "1px solid rgba(224, 229, 242, 1)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />
          </Grid>
        ))}
      </Grid2>

      <div
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
          fontWeight: 500,
          color: "rgba(252, 71, 71, 1)",
          lineHeight: "16px",
          marginTop: "20px",
        }}
      >
        {error && t("invalid_code")}
      </div>

      <div
        className="not-registred-yet-container"
        style={{
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
            fontWeight: 500,
            color: "rgba(43, 54, 116, 1)",
            fontSize: "16px",
            letterSpacing: "-0.32px",
            lineHeight: "26px",
          }}
        >
          {t("dont_recive_email")}
        </div>
        <div
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
            fontWeight: isRTL ? 600 : 500,
            color: "rgba(215, 161, 72, 1)",
            fontSize: "16px",
            letterSpacing: "-0.32px",
            lineHeight: "26px",
            cursor: "pointer",
          }}
        >
          {t("click_to_resend")}
        </div>
      </div>
      <div onClick={onSubmit}>submit</div>

      <BackToLoginBtn />
    </div>
  );
};
