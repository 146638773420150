import { Divider, Drawer } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useAppDispatch } from "../../../store/store";
import {
  mapSideBarOpenSelector,
  setMapSideBarClose,
} from "../../../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import CrossGrey from "../../../assets/icons/cross_grey.svg";
import GreyPlus from "../../../assets/icons/grey_plus.svg";
import GreyMinus from "../../../assets/icons/grey_minus.svg";
import { Property } from "../../../helpers/convert";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MappedPropertyPoint, TooltipElement } from "./MapUpMd";
import BlueTooltip from "../../assets/icons/tooltip_blue.svg";
import { convertToPoints, formatPrice } from "../../../helpers/formatter";
import { MapProperyPopup } from "./MapProperyPopup";
import { ControlMapPanel } from "./ControlMapPanel";

export const MapSideBar = ({ properties }: { properties: Property[] }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const mapSideBarOpen = useSelector(mapSideBarOpenSelector);

  const [viewState, setViewState] = React.useState({
    latitude: 0,
    longitude: 0,
    zoom: 10,
  });
  const [points, setPoints] = React.useState<MappedPropertyPoint[]>([]);

  React.useEffect(() => {
    const points = convertToPoints(properties);
    setPoints(points);

    if (points.length > 0) {
      setViewState({
        latitude: points[0].latitude,
        longitude: points[0].longitude,
        zoom: 10,
      });

      // setViewState({
      //   latitude: 31.771959,
      //   longitude: 35.217018,
      //   zoom: 10,
      // });
    }
  }, [properties]);

  const handleZoomIn = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.min(prev.zoom + 1, 22),
    }));
  };

  const handleZoomOut = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.max(prev.zoom - 1, 0),
    }));
  };
  const [clickedPointId, setClickedPointId] = React.useState<number | null>(
    null
  );
  const [selectedPoint, setSelectedPoint] =
    React.useState<MappedPropertyPoint | null>(null);

  const handleMarkerClick = (point: MappedPropertyPoint) => {
    setSelectedPoint(point);
    setClickedPointId(point.id);
  };

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          height: "100%",
          position: "relative",
        },
      }}
      anchor={"top"}
      open={mapSideBarOpen}
      onClose={() => dispatch(setMapSideBarClose())}
    >
      <div
        onClick={() => {
          dispatch(setMapSideBarClose());
          setClickedPointId(null);
        }}
        style={{
          backgroundColor: "rgba(255, 255, 255, 1)",
          position: "absolute",
          top: "18px",
          left: "18px",
          width: "31px",
          height: "31px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid rgba(225, 228, 241, 1)",
          zIndex: 300,
          cursor: "pointer",
        }}
      >
        <img src={CrossGrey} />
      </div>
      <ControlMapPanel
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
      />
      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken="pk.eyJ1Ijoic2VudG9tZGV2IiwiYSI6ImNtNDJvNmJzODAzNW0ya3NmaTBmNmFvZmsifQ.hiEcmq9fuyls-z-6rq0TvA"
      >
        {points.map((point) => (
          <Marker
            key={point.id}
            latitude={point.latitude}
            longitude={point.longitude}
            anchor="bottom"
            style={{
              cursor: "pointer",
            }}
          >
            <TooltipElement
              onClick={() => handleMarkerClick(point)}
              isClicked={clickedPointId === point.id}
            >
              {isRTL ? "₪" : "$"}
              {formatPrice(point.basePrice)}
            </TooltipElement>
          </Marker>
        ))}
        {selectedPoint && (
          <MapProperyPopup
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
          />
        )}
      </Map>
    </Drawer>
  );
};
