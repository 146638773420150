import React from "react";
import { useTranslation } from "react-i18next";
import GoogleIcon from "../../assets/icons/google.svg";

export const SignWithGoogleBtn = () => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  return (
    <div
      className="signin-form-google-btn"
      style={{
        direction: isRTL ? "rtl" : "ltr",
        gap: isRTL ? "17px" : "9px",
      }}
    >
      <img src={GoogleIcon} />
      <div
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
          fontWeight: isRTL ? 400 : 500,
        }}
      >
        {t("sign_in_with_google")}
      </div>
    </div>
  );
};
