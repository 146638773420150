import React from "react";
import DoneIcon from "../../assets/icons/done.svg";
import { useTranslation } from "react-i18next";
import WhiteArrowLeft from "../../assets/icons/white_arrow_left.svg";

export const PasswordResetDoneStep = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <div className="forgot-p-container">
      <div className="forgot-p-lock-container">
        <img src={DoneIcon} />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
        }}
      >
        {t("all_done_title")}
      </div>
      <div
        className="forgot-p-desc"
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {t("all_done_desc")}
      </div>

      <button
        onClick={onSubmit}
        className="signin-form-inputs-signin-btn"
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
          fontWeight: isRTL ? 700 : 500,
          gap: "5px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <img src={WhiteArrowLeft} />
        </div>
        <div>{t("back_to_login")}</div>
      </button>
    </div>
  );
};
