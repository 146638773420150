import { Slider } from "@mui/material";
import React from "react";
import { formatPrice } from "../../helpers/formatter";
import { useTranslation } from "react-i18next";

export const PriceRangeSlider = ({
  priceRangeValue,
  handleChange,
  styles,
}: {
  priceRangeValue: number[];
  handleChange: (event: Event, newValue: number | number[]) => void;
  styles: {
    color: string;
  };
}) => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const formatValueLabel = (value: number) => {
    return `${isRTL ? "₪" : "$"} ${formatPrice(value)}`;
  };

  return (
    <div
      style={{
        paddingTop: "20px",
      }}
    >
      <Slider
        value={priceRangeValue}
        onChange={handleChange}
        max={100000000}
        valueLabelDisplay="on"
        valueLabelFormat={formatValueLabel}
        sx={{
          "& .MuiSlider-thumb": {
            backgroundColor: "rgba(3, 74, 115, 1)",
            width: "14px",
            height: "14px",
            "&:hover": {
              boxShadow: "0 0 0 8px #034a7324",
            },
          },
          "& .MuiSlider-track": {
            backgroundColor: "rgba(3, 74, 115, 1)",
            border: "none",
            height: "7px",
          },
          "& .MuiSlider-rail": {
            opacity: 0.5,
            backgroundColor: "rgba(169, 188, 196, 1)",
            height: "7px",
          },
          "& .MuiSlider-valueLabel": {
            backgroundColor: "transparent",
            color: "rgba(0, 0, 0, 1)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "14px",
            fontFamily: "DM Sans",
            transform: "translateY(-50%)",
            p: 0,
          },
        }}
      />
    </div>
  );
};
