import { Box, Theme, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Navbar = () => {
  const { t } = useTranslation(["navbar"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const navItems = [
    { title: t("home"), path: "/home" },
    { title: t("portfolio"), path: "/portfolio" },
    { title: t("sell_with_us"), path: "/sell-together" },
    { title: t("learning_center"), path: "/learn" },
  ];

  return (
    <div
      style={{
        gap: downLg ? "20px" : "32px",
        display: "flex",
      }}
    >
      {navItems.map((item, index) => (
        <Box key={index} component="span">
          <NavLink
            to={item.path}
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#000" : "#00000087",
              fontWeight: isRTL ? 400 : 500,
              fontFamily: isRTL ? "Alef" : "DM Sans",
              cursor: "pointer",
              fontSize: downLg ? "14px" : "16px",
            })}
          >
            {item.title}
          </NavLink>
        </Box>
      ))}
    </div>
  );
};
