import { ISortParams } from "../../types/properties";
import { apiSlice } from "../api/apiSlice";

const buildQueryParams = <T extends Record<string, unknown>>(
  filters: T | null | undefined
): URLSearchParams => {
  const params = new URLSearchParams();

  const safeEntries = (obj: T | null | undefined): [string, any][] => {
    if (!obj || typeof obj !== "object") return [];
    return Object.entries(obj);
  };

  const flattenObject = (
    obj: T | null | undefined,
    prefix = "filters"
  ): void => {
    safeEntries(obj).forEach(([key, value]) => {
      if (value === null || value === undefined) return;

      if (key === "$or" && Array.isArray(value)) {
        value.forEach((orCondition, index) => {
          if (orCondition && typeof orCondition === "object") {
            safeEntries(orCondition).forEach(([nestedKey, nestedValue]) => {
              if (nestedValue && typeof nestedValue === "object") {
                safeEntries(nestedValue).forEach(([deepKey, deepValue]) => {
                  if (deepValue !== null && deepValue !== undefined) {
                    params.append(
                      `${prefix}[${key}][${index}][${nestedKey}][${deepKey}]`,
                      String(deepValue)
                    );
                  }
                });
              } else if (nestedValue !== null && nestedValue !== undefined) {
                params.append(
                  `${prefix}[${key}][${index}][${nestedKey}]`,
                  String(nestedValue)
                );
              }
            });
          }
        });
      } else if (value && typeof value === "object" && !Array.isArray(value)) {
        flattenObject(value, `${prefix}[${key}]`);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item !== null && item !== undefined) {
            params.append(`${prefix}[$or][${index}][${key}]`, String(item));
          }
        });
      } else if (value !== null && value !== undefined) {
        params.append(`${prefix}[${key}]`, String(value));
      }
    });
  };

  flattenObject(filters);
  return params;
};

interface FilterParams {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | FilterParams
    | FilterParams[];
}

// filters[$or][0][location][city]=Haifa&filters[$or][1][location][city]=Jerusalem&filters[$or][2][location][city]=Herzliya

// filters[pricing][basePrice][$gte]: 26215820
// filters[pricing][basePrice][$lte]: 86528320

// function generateQueryParams(filters: FilterParams): URLSearchParams {
//   const params = new URLSearchParams();

//   function appendParam(key: string, value: string | number | boolean | null) {
//     if (value !== null && value !== undefined) {
//       params.append(key, String(value));
//     }
//   }

//   function processFilters(filterObj: FilterParams, parentKey = "") {
//     Object.entries(filterObj).forEach(([key, value]) => {
//       const currentKey = parentKey ? `${parentKey}[${key}]` : key;

//       if (key === "pricing") {
//         Object.entries(value as FilterParams).forEach(
//           ([nestedKey, nestedValue]) => {
//             Object.entries(nestedValue as FilterParams).forEach(
//               ([operator, operatorValue]) => {
//                 appendParam(
//                   `filters[pricing][${nestedKey}][${operator}]`,
//                   operatorValue as any
//                 );
//               }
//             );
//           }
//         );
//       } else if (key === "propertyType" || key === "propertyStatus") {
//         if (Array.isArray(value)) {
//           value.forEach((item, index) => {
//             appendParam(`filters[$or][${index}][${key}]`, item as any);
//           });
//         } else {
//           appendParam(`filters[${key}]`, value as any);
//         }
//       } else if (Array.isArray(value)) {
//         value.forEach((item, index) => {
//           if (typeof item === "object" && item !== null) {
//             if (key === "$or") {
//               Object.entries(item).forEach(([nestedKey, nestedValue]) => {
//                 processFilters(
//                   nestedValue as FilterParams,
//                   `filters[${currentKey}][${index}][${nestedKey}]`
//                 );
//               });
//             } else {
//               processFilters(item, `${currentKey}[${index}]`);
//             }
//           } else {
//             appendParam(`${currentKey}[${index}]`, item);
//           }
//         });
//       } else if (typeof value === "object" && value !== null) {
//         processFilters(value, currentKey);
//       } else {
//         appendParam(currentKey, value);
//       }
//     });
//   }

//   processFilters(filters);
//   return params;
// }

export interface IGetPropertiesTestParams {
  locale: string;
  filters?: Record<string, any>;
  sort?: ISortParams;
  pagination?: { pageSize: number; page: number };
  name?: string | null;
}

function CreateGetPropertiesTestURLQuery(
  params: IGetPropertiesTestParams
): string {
  const urlRarams = new URLSearchParams();
  urlRarams.append("populate", "mediaContent");
  urlRarams.append("locale", params.locale);

  // if (params.filters) {
  //   const filterParams = generateQueryParams(params.filters);
  //   filterParams.forEach((value, key) => {
  //     urlRarams.append(key, value);
  //   });
  // }
  const queryParams = params.filters
    ? buildQueryParams(params.filters)
    : new URLSearchParams();

  queryParams.forEach((value, key) => {
    urlRarams.append(key, value);
  });

  if (params.sort && params.sort.sortParam && params.sort.sortDirection) {
    const filteredSortParam = params.sort.sortParam.filter(
      (param: string) => param !== ""
    );

    if (filteredSortParam.length === 1) {
      urlRarams.append(
        "sort",
        `${filteredSortParam[0]}:${params.sort.sortDirection}`
      );
    } else {
      urlRarams.append(
        "sort",
        `[${filteredSortParam.join("][")}]:${params.sort.sortDirection}`
      );
    }
  }

  if (params.pagination) {
    urlRarams.append(
      "pagination[pageSize]",
      params.pagination.pageSize.toString()
    );
    urlRarams.append("pagination[page]", params.pagination.page.toString());
  }

  if (params.name) {
    urlRarams.append("filters[name][$contains]", params.name);
  }

  return `/properties?${urlRarams.toString()}`;
}

export const propertyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: ({ locale }: { locale: string }) => {
        return `/properties?populate=mediaContent&locale=${locale}`;
      },
    }),

    getPropertiesTest: builder.query({
      query: (params: IGetPropertiesTestParams) => {
        return CreateGetPropertiesTestURLQuery(params);
      },
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useGetPropertiesTestQuery,
  useLazyGetPropertiesTestQuery,
} = propertyApiSlice;
