import React from "react";
import { AuthWrapper } from "../components/_common/AuthWrapper";
import { useTranslation } from "react-i18next";
import { SignWithGoogleBtn } from "../components/forgotPassword/SignWithGoogleBtn";
import { ORBlock } from "../components/forgotPassword/ORBlock";
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
} from "@mui/material";
import IsraelFlag from "../assets/flags/israel.svg";
import { PhoneSelect } from "../components/_common/PhoneSelect";
import Visibility from "../assets/icons/visibility.svg";
import VisibilityOff from "../assets/icons/visibility_off.svg";

export const countryCodes = [
  { code: "+920", label: "Israel", flag: IsraelFlag },
  { code: "+44", label: "United Kingdom", flag: "🇬🇧" },
  { code: "+91", label: "India", flag: "🇮🇳" },
];

export const authTextFieldStyles = {
  textField: (theme: Theme, isRTL: boolean) => ({
    "& .MuiOutlinedInput-root": {
      border: "1px solid rgba(224, 229, 242, 1)",
      borderRadius: "16px",
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      border: "1px solid rgba(129, 154, 221, 1)",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      color: "rgba(43, 54, 116, 1)",
      fontFamily: isRTL ? "Alef" : "DM Sans",
      fontWeight: isRTL ? 400 : 500,
      lineHeight: "14px",
      letterSpacing: "-0.32px",
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: "rgba(163, 174, 208, 1)",
      opacity: 1,
    },
    "& .MuiOutlinedInput-input:focus::placeholder": {
      opacity: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px rgba(255, 255, 255, 1) inset",
      WebkitTextFillColor: "rgba(43, 54, 116, 1)",
      transition: "background-color 5000s ease-in-out 0s",
    },
  }),
};

export const Signup = () => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const [selectedCode, setSelectedCode] = React.useState("+920");
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleCodeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCode(event.target.value);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPhoneNumber(event.target.value);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <AuthWrapper>
      <div className="signin-form-section">
        <div
          className="signin-form-container"
          style={{
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <div
            className="signin-form-title-container"
            style={{
              direction: isRTL ? "rtl" : "ltr",
            }}
          >
            <div
              className="signin-form-title-signin"
              style={{
                fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
                textTransform: "capitalize",
              }}
            >
              {t("sign_up")}
            </div>
            <div
              className="signin-vertical-divider"
              style={{
                marginRight: isRTL ? "12px" : "16px",
                marginLeft: isRTL ? "12px" : "20px",
              }}
            ></div>
            <div
              className="signin-form-title-welcom"
              style={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
              }}
            >
              {t("welcome_on_board")}
            </div>
          </div>

          <SignWithGoogleBtn />
          <ORBlock />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("full_name")}
          </div>
          <TextField
            type="email"
            fullWidth
            required
            placeholder={t("full_name_example")}
            sx={(theme) => authTextFieldStyles.textField(theme, isRTL)}
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: isRTL ? "Alef" : "DM Sans",
              marginTop: "15px",
            }}
          >
            {t("email")}
          </div>
          <TextField
            type="email"
            fullWidth
            required
            placeholder={t("email_placeholder")}
            sx={(theme) => authTextFieldStyles.textField(theme, isRTL)}
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: isRTL ? "Alef" : "DM Sans",
              marginTop: "15px",
            }}
          >
            {t("phone_number")}
          </div>

          <TextField
            type="tel"
            fullWidth
            required
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneSelect
                    selectedCode={selectedCode}
                    handleCodeChange={handleCodeChange}
                  />
                </InputAdornment>
              ),
            }}
            sx={(theme) => authTextFieldStyles.textField(theme, isRTL)}
          />

          <div
            className="signin-form-inputs-label"
            style={{
              fontFamily: isRTL ? "Alef" : "DM Sans",
              marginTop: "15px",
            }}
          >
            {t("password")}
          </div>
          <TextField
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            placeholder={t("password_placeholder")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <img
                        src={VisibilityOff}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                    ) : (
                      <img
                        src={Visibility}
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={(theme) => authTextFieldStyles.textField(theme, isRTL)}
          />
        </div>
      </div>
    </AuthWrapper>
  );
};
