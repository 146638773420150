import React, { Dispatch, SetStateAction, useState } from "react";
import { useEffect } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Location, Media, Property } from "../../../helpers/convert";
import i18n2 from "../../../i18n";
import { useTranslation } from "react-i18next";
import { convertToPoints, formatPrice } from "../../../helpers/formatter";
import { styled } from "@mui/material";
import { MapProperyPopup } from "./MapProperyPopup";
import { ControlMapPanel } from "./ControlMapPanel";

export type MappedPropertyPoint = {
  id: number;
  latitude: number;
  longitude: number;
  title: string;
  basePrice: number;
  mainImg: Media;
  location: Location;
};

export const MapUpMd = ({
  properties,
  setVisibleMarkerIds,
}: {
  properties: Property[];
  setVisibleMarkerIds: Dispatch<SetStateAction<number[]>>;
}) => {
  const currentLanguage = i18n2.language;
  const isRTL = currentLanguage === "he";
  const { i18n } = useTranslation();
  const mapContainerRef = React.useRef<HTMLDivElement | null>(null);

  const [viewState, setViewState] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 10,
  });
  const [points, setPoints] = useState<MappedPropertyPoint[]>([]);
  const [clickedPointId, setClickedPointId] = useState<number | null>(null);
  const [selectedPoint, setSelectedPoint] =
    useState<MappedPropertyPoint | null>(null);

  useEffect(() => {
    const points = convertToPoints(properties);

    setPoints(points);

    if (points.length > 0) {
      setViewState({
        latitude: points[0].latitude,
        longitude: points[0].longitude,
        zoom: 8,
      });

      // setViewState({
      //   latitude: 31.771959,
      //   longitude: 35.217018,
      //   zoom: 10,
      // });
    }
  }, [properties]);

  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/mapbox/streets-v11"
  );

  useEffect(() => {
    setMapStyle(
      currentLanguage === "he"
        ? "mapbox://styles/mapbox/streets-v11?localizeLabels=true&lang=he"
        : "mapbox://styles/mapbox/streets-v11?localizeLabels=true&lang=en"
    );
  }, [i18n.language]);

  const handleMarkerClick = (point: MappedPropertyPoint) => {
    setSelectedPoint(point);
    setClickedPointId(point.id);
  };

  const updateVisibleMarkers = React.useCallback(
    (map: any) => {
      const bounds = map.getBounds();
      const visibleIds = points
        .filter((point) => bounds.contains([point.longitude, point.latitude]))
        .map((point) => point.id);

      setVisibleMarkerIds(visibleIds);
    },
    [points]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        mapContainerRef.current &&
        !mapContainerRef.current.contains(event.target as Node)
      ) {
        setSelectedPoint(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleZoomIn = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.min(prev.zoom + 1, 22),
    }));
  };

  const handleZoomOut = () => {
    setViewState((prev) => ({
      ...prev,
      zoom: Math.max(prev.zoom - 1, 0),
    }));
  };

  return (
    <div
      ref={mapContainerRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <ControlMapPanel
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
      />
      <Map
        {...viewState}
        onMove={(evt) => {
          setViewState(evt.viewState);
          updateVisibleMarkers(evt.target);
        }}
        style={{ width: "100%", height: "100%" }}
        mapStyle={mapStyle}
        mapboxAccessToken="pk.eyJ1Ijoic2VudG9tZGV2IiwiYSI6ImNtNDJvNmJzODAzNW0ya3NmaTBmNmFvZmsifQ.hiEcmq9fuyls-z-6rq0TvA"
      >
        {points.map((point) => (
          <Marker
            key={point.id}
            latitude={point.latitude}
            longitude={point.longitude}
            anchor="bottom"
            style={{
              cursor: "pointer",
            }}
          >
            <TooltipElement
              onClick={() => handleMarkerClick(point)}
              isClicked={clickedPointId === point.id}
            >
              {isRTL ? "₪" : "$"}
              {formatPrice(point.basePrice)}
            </TooltipElement>
          </Marker>
        ))}

        {selectedPoint && (
          <MapProperyPopup
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
          />
        )}
      </Map>
    </div>
  );
};

export const TooltipElement = styled("div")<{ isClicked: boolean }>(
  ({ theme, isClicked }) => ({
    position: "relative",
    width: "60px",
    height: "12px",
    backgroundColor: isClicked
      ? "rgba(215, 161, 72, 1)"
      : "rgba(3, 74, 115, 1)",
    padding: "8px 12px",
    borderRadius: "4px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontFamily: "DM Sans",
    fontSize: "16px",
    lineHeight: "16px",
    color: "rgba(255, 255, 255, 1)",
    justifyContent: "center",

    "&:hover": {
      backgroundColor: "rgba(215, 161, 72, 1)",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-5px",
      borderWidth: "7px",
      borderStyle: "solid",
      borderColor: isClicked
        ? "rgba(215, 161, 72, 1) transparent transparent transparent"
        : "rgba(3, 74, 115, 1) transparent transparent transparent",
    },
    "&:hover::after": {
      borderColor: "rgba(215, 161, 72, 1) transparent transparent transparent",
    },
  })
);
