import { Box, Drawer, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import PropTypes from "prop-types";

import Sparkles from "../../assets/icons/sparkles.svg";
import { BuyTabPanelContent } from "./BuyTabPanelContent";
import { AskAITabPanelContent } from "./AskAITabPanelContent";
import { useAppDispatch } from "../../store/store";
import {
  filterSideBarOpenSelector,
  setFilterSideBarClose,
} from "../../store/features/portfolioSlice";
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: "20px", height: "100%" }}>
          <Box height={"100%"}>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const FilterSideBar = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const filterSideBarOpen = useSelector(filterSideBarOpenSelector);

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChangeSelectedTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTab(newValue);
  };

  const [clearTrigger, setClearTrigger] = React.useState(0);

  const handleClear = () => {
    setClearTrigger((prev) => prev + 1);
  };

  return (
    <Drawer
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "18px 18px 0 0",
          height: "630px",
        },
      }}
      anchor={"bottom"}
      open={filterSideBarOpen}
      onClose={() => dispatch(setFilterSideBarClose())}
    >
      <div
        className="filter-side-bar-container "
        style={{
          paddingTop: "18px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="filter-side-bar-line"
            style={{
              width: "160px",
              height: "4px",
              backgroundColor: "rgba(0, 0, 0, 0.17)",
              borderRadius: "3px",
            }}
          ></div>
        </div>
        <div
          className="filter-side-title-menu"
          style={{
            flexDirection: isRTL ? "row-reverse" : "row",
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("filters")}
          </span>
          <span
            onClick={handleClear}
            style={{
              fontWeight: isRTL ? 700 : 500,
              fontFamily: isRTL ? "Alef" : "DM Sans",
              color: "rgba(3, 74, 115, 1)",
              cursor: "pointer",
            }}
          >
            {t("clear")}
          </span>
        </div>

        <div
          style={{
            direction: isRTL ? "rtl" : "ltr",
            height: selectedTab === 2 ? "100%" : "auto",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChangeSelectedTab}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "rgba(215, 161, 72, 1)",
              },
              "& .MuiTabs-scroller": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.09)",
              },
              display: "flex",
              alignItems: "center",
            }}
            orientation="horizontal"
          >
            <Tab
              label={t("buy")}
              sx={{
                textTransform: "none",
                color:
                  selectedTab === 0 ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)",
                fontWeight: 500,
                "&.Mui-selected": {
                  color: "rgba(0, 0, 0, 1)",
                },
                p: 0,
                mb: "10px",
                minHeight: "fit-content",
                minWidth: "fit-content",
                mr: isRTL ? "20px" : "35px",
                ml: isRTL ? "35px" : "20px",
                fontSize: "16px",
              }}
            />
            <Tab
              label={t("rent")}
              sx={{
                textTransform: "none",
                color:
                  selectedTab === 1 ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.5)",
                fontWeight: 500,
                "&.Mui-selected": {
                  color: "rgba(0, 0, 0, 1)",
                },
                p: 0,
                mb: "10px",
                minWidth: "fit-content",
                mr: isRTL ? 0 : "35px",
                ml: isRTL ? "35px" : "0",
                minHeight: "fit-content",
                fontSize: "16px",
              }}
            />
            <Tab
              label=<div
                style={{
                  display: "flex",
                  flexDirection: isRTL ? "row-reverse" : "row",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <img src={Sparkles} />
                <div
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    fontSize: "16px",
                  }}
                >
                  {t("search")}
                </div>
              </div>
              sx={{
                textTransform: "none",
                color:
                  selectedTab === 2
                    ? "rgba(0, 0, 0, 1)"
                    : "rgba(104, 191, 210, 1)",
                fontWeight: 500,
                p: 0,
                mb: "10px",
                minHeight: "fit-content",
                minWidth: "fit-content",
                mr: isRTL ? 0 : "35px",
                ml: isRTL ? "35px" : "0",
                "&.Mui-selected": {
                  color: "rgba(0, 0, 0, 1)",
                },
              }}
            />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <BuyTabPanelContent clearTrigger={clearTrigger} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <BuyTabPanelContent clearTrigger={clearTrigger} />
          </TabPanel>

          <TabPanel value={selectedTab} index={2}>
            <AskAITabPanelContent />
          </TabPanel>
        </div>
      </div>
    </Drawer>
  );
};
