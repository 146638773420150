import { Box, Grid, Icon, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Property } from "../../../helpers/convert";
import LocationPin from "../../../assets/icons/location_pin.svg";

export const ExploreTopListing = ({
  properties,
}: {
  properties: Property[];
}) => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [activeIndexState, setIndexState] = React.useState<number | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const openPropertyPanel = (index: number) => {
    if (downMd) {
      setIndexState(activeIndexState === index ? null : index);
    }
  };

  React.useEffect(() => {
    setIndexState(null);
  }, [downMd]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setIndexState(null);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // TODO refactor
  if (properties.length < 1) {
    return <></>;
  }

  return (
    <div
      className="explore-top-listings-container xl-width"
      style={{
        marginBottom: downMd ? "50px" : "100px",
        marginTop: downSm ? "30px" : downMd ? "50px" : "130px",
      }}
    >
      <div
        className="explore-top-listings-titles"
        style={{
          marginRight: "20px",
          marginLeft: "20px",
          textAlign: isRTL ? "end" : "start",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
            color: "rgba(79, 129, 157, 1)",
            mb: downMd ? "5px" : "0",
            fontWeight: 600,
          }}
        >
          {t("featured_properties")}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
            textTransform: "uppercase",
            mb: downMd ? "25px" : "45px",
          }}
        >
          {t("explore_top_listings")}
        </Typography>
      </div>

      <Box
        ref={containerRef}
        className="explore-top-listings-gallery-container"
        sx={{
          display: "grid",
          marginRight: "20px",
          marginLeft: "20px",
          height: downMd ? " auto" : "546px",
          gridTemplateAreas: {
            xs: `
        "img1 img2"
        "img3 img3"
        "img4 img4"
      `,
            sm: `
        "img1 img2"
        "img3 img3"
        "img4 img4"
      `,
            md: `
        "img1 img2 img4"
        "img1 img3 img4"
      `,
            lg: `
        "img1 img2 img4"
        "img1 img3 img4"
      `,
          },
          gridTemplateColumns: {
            xs: "1fr 1fr",
            sm: "1fr 1fr",
            md: "1fr 1fr 1fr",
            lg: "1fr 1fr 1fr",
          },
          gap: downMd ? "10px" : "20px",
        }}
      >
        <Box
          onClick={() => openPropertyPanel(0)}
          sx={{
            gridArea: "img1",
            position: "relative",
            borderRadius: "20px",
            height: downMd ? "343px" : "auto",
            background: `url(${properties[0].mainImage.url}) center center / cover no-repeat`,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: downMd ? (activeIndexState === 0 ? 1 : 0) : 0,
              transition: "opacity 0.3s ease",
              flexDirection: "column",
            }}
            className="hover-content"
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: 600,
                textAlign: "center",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              {properties[0].name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                backdropFilter: "blur(60px)",
                backgroundColor: "rgba(255, 255, 255, 0.38)",
                borderRadius: "24px",
                p: "9px 20px",
                position: "absolute",
                bottom: "17px",
                left: downMd ? "20px" : "17px",
                direction: isRTL ? "rtl" : "ltr",
                gap: "5px",
              }}
            >
              <img src={LocationPin} />
              <Typography
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                }}
              >
                {properties[0].location.city}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          onClick={() => openPropertyPanel(1)}
          sx={{
            gridArea: "img2",
            position: "relative",
            borderRadius: "20px",
            height: downMd ? "343px" : "auto",
            background: `url(${properties[1].mainImage.url}) center center / cover no-repeat`,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: downMd ? (activeIndexState === 1 ? 1 : 0) : 0,

              transition: "opacity 0.3s ease",
              flexDirection: "column",
            }}
            className="hover-content"
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: 600,
                textAlign: "center",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              {properties[1].name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                backdropFilter: "blur(60px)",
                backgroundColor: "rgba(255, 255, 255, 0.38)",
                borderRadius: "24px",
                p: "9px 20px",
                position: "absolute",
                bottom: "17px",
                left: downMd ? "20px" : "17px",
                direction: isRTL ? "rtl" : "ltr",
                gap: "5px",
              }}
            >
              <img src={LocationPin} />
              <Typography
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                }}
              >
                {properties[1].location.city}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          onClick={() => openPropertyPanel(2)}
          sx={{
            gridArea: "img3",
            position: "relative",
            borderRadius: "20px",
            height: downMd ? "168px" : "auto",
            background: `url(${properties[2].mainImage.url}) center center / cover no-repeat`,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: downMd ? (activeIndexState === 2 ? 1 : 0) : 0,

              transition: "opacity 0.3s ease",
              flexDirection: "column",
            }}
            className="hover-content"
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: 600,
                textAlign: "center",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              {properties[2].name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                backdropFilter: "blur(60px)",
                backgroundColor: "rgba(255, 255, 255, 0.38)",
                borderRadius: "24px",
                p: "9px 20px",
                position: "absolute",
                bottom: "17px",
                left: downMd ? "20px" : "17px",
                direction: isRTL ? "rtl" : "ltr",
                gap: "5px",
              }}
            >
              <img src={LocationPin} />
              <Typography
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                }}
              >
                {properties[2].location.city}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          onClick={() => openPropertyPanel(3)}
          sx={{
            gridArea: "img4",
            position: "relative",
            borderRadius: "20px",
            height: downMd ? "168px" : "auto",
            background: `url(${properties[3].mainImage.url}) center center / cover no-repeat`,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: downMd ? (activeIndexState === 3 ? 1 : 0) : 0,
              transition: "opacity 0.3s ease",
              flexDirection: "column",
            }}
            className="hover-content"
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: 600,
                textAlign: "center",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              {properties[3].name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                backdropFilter: "blur(60px)",
                backgroundColor: "rgba(255, 255, 255, 0.38)",
                borderRadius: "24px",
                p: "9px 20px",
                position: "absolute",
                bottom: "17px",
                left: downMd ? "20px" : "17px",
                direction: isRTL ? "rtl" : "ltr",
                gap: "5px",
              }}
            >
              <img src={LocationPin} />
              <Typography
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                }}
              >
                {properties[3].location.city}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
