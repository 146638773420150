import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthWrapper } from "../components/_common/AuthWrapper";
import FingerPrint from "../assets/icons/fingerprint.svg";
import { TextField } from "@mui/material";
import YellowArrow from "../assets/icons/yellow_arrow.svg";
import { PasswordResetEnterEmailStep } from "../components/forgotPassword/PasswordResetEnterEmailStep";
import { PasswordResetConfirmEmailStep } from "../components/forgotPassword/PasswordResetConfirmEmailStep";
import { PasswordResetNewPasswordStep } from "../components/forgotPassword/PasswordResetNewPasswordStep";
import { PasswordResetDoneStep } from "../components/forgotPassword/PasswordResetDoneStep";

export enum ForgotPasswordStep {
  enterEmail = "enterEmail",
  confirmEmail = "confirmEmail",
  newPassword = "newPassword",
  done = "done",
}

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const [currentStep, setCurrentStep] = React.useState<ForgotPasswordStep>(
    ForgotPasswordStep.enterEmail
  );

  return (
    <AuthWrapper>
      <div className="forgot-p-section">
        {currentStep === ForgotPasswordStep.enterEmail && (
          <PasswordResetEnterEmailStep
            onSubmit={() => setCurrentStep(ForgotPasswordStep.confirmEmail)}
          />
        )}

        {currentStep === ForgotPasswordStep.confirmEmail && (
          <PasswordResetConfirmEmailStep
            onSubmit={() => setCurrentStep(ForgotPasswordStep.newPassword)}
          />
        )}

        {currentStep === ForgotPasswordStep.newPassword && (
          <PasswordResetNewPasswordStep
            onSubmit={() => setCurrentStep(ForgotPasswordStep.done)}
          />
        )}

        {currentStep === ForgotPasswordStep.done && (
          <PasswordResetDoneStep
            onSubmit={() => setCurrentStep(ForgotPasswordStep.confirmEmail)}
          />
        )}

        <div className="step-indicator">
          {Object.values(ForgotPasswordStep).map((step, index) => (
            <div
              key={step}
              className={`step ${currentStep === step ? "active" : ""}`}
              style={{
                width: currentStep === step ? "68px" : "40px",
                height: "5px",
                backgroundColor:
                  currentStep === step
                    ? "rgba(3, 74, 115, 1)"
                    : "rgba(3, 74, 115, 0.5)",
                borderRadius: "5px",
              }}
            />
          ))}
        </div>
      </div>
    </AuthWrapper>
  );
};
