import React from "react";
import { useTranslation } from "react-i18next";

export const ORBlock = () => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  return (
    <div className="signin-form-or-container">
    <div className="signin-horizontal-line"></div>
    <div
      className="signin-form-or-text"
      style={{
        fontFamily: isRTL ? "Alef" : "DM Sans",
        fontWeight: isRTL ? 400 : 500,
      }}
    >
      {t("or")}
    </div>
    <div className="signin-horizontal-line"></div>
  </div>
  );
};
