import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { countryCodes } from "../../pages/Signup";

export const PhoneSelect = ({
  selectedCode,
  handleCodeChange,
}: {
  selectedCode: string;
  handleCodeChange: (event: SelectChangeEvent<string>) => void;
}) => {
  return (
    <Select
      value={selectedCode}
      onChange={handleCodeChange}
      variant="standard"
      disableUnderline
      sx={{
        display: "flex",
        alignItems: "center",
        minWidth: "80px",
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
          gap: "4px",
          fontSize: "14px",
        },
      }}
    >
      {countryCodes.map((country) => (
        <MenuItem key={country.code} value={country.code}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <img src={country.flag} />
            {country.code}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
