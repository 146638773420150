import React from "react";
import { useTranslation } from "react-i18next";
import { Theme, Typography, useMediaQuery } from "@mui/material";
import { PortfolioFilterUpMd } from "./PortfolioFilterUpMd";
import { PortfolioFilterDownMd } from "./PortfolioFilterDownMd";

export const PortfolioHeroImg = () => {
  const { t } = useTranslation(["portfolio"]);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <div
      className="portfolio-hero-img-wrapper"
      style={{
        direction: isRTL ? "rtl" : "ltr",
        height: upLg ? "506px" : downMd ? "293px" : "506px",
      }}
    >
      <div
        className="portfolio-hero-img-container  xl-width"
        style={{
          paddingTop: "93px",
          paddingBottom: "94px",
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <div className="portfolio-hero-img-info-container">
          <Typography
            variant="h1"
            sx={{
              fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
              color: "rgba(255, 255, 255, 1)",
              fontWeight: isRTL ? 700 : 600,
              lineHeight: downMd ? "28px" : "52px",
              textTransform: "uppercase",
              maxWidth: "714px",
              letterSpacing: isRTL ? "-1.64px" : "",
              mb: "9px",
            }}
          >
            {t("portfolio-hero-img-title")}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: isRTL ? "Alef" : "DM Sans",
              color: "rgba(255, 255, 255, 0.85)",
              fontWeight: 400,
              lineHeight: "22px",
              maxWidth: "510px",
              letterSpacing: isRTL ? "-1.64px" : "",
            }}
          >
            {t("portfolio-hero-img-desc")}
          </Typography>
        </div>

        {downMd ? <PortfolioFilterDownMd /> : <PortfolioFilterUpMd />}
      </div>
    </div>
  );
};
