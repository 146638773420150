import { Header } from "../components/_common/Header";
import { Navbar } from "../components/_common/Navbar";
import { Overview } from "../components/home/Overview";
import { HeroImg } from "../components/home/HeroImg/HeroImg";
import { FeaturedProperties } from "../components/home/FeaturedProperties";
import { PropertyTypesBlock } from "../components/home/PropertyTypesBlock";
import { Footer } from "../components/_common/Footer";
import { useGetPropertiesQuery } from "../store/api/propertyApiSlice";
import { AriaAttributes, useEffect, useState } from "react";
import { randomMediaGenerator } from "../helpers/fakeMedia";
import { FAKE_PROPERTIES, getRandomProperty } from "../helpers/fakeProperties";
import { convertToProperties, Property } from "../helpers/convert";
import { GetInTouchBlock } from "../components/home/GetInTouchBlock";
import { Testimonials } from "../components/home/Testimonials/Testimonials";
import { FooterSocialMediaLine } from "../components/_common/FooterSocialMediaLine";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import { ForBuyers } from "../components/home/ForBuyers/ForBuyers";
import { ExploreTopListing } from "../components/home/ExploreBlock/ExploreTopListing";
import { IInitialProperty } from "../types/initialProperty";

export const PREFERED_PROPERTY_NUM = 5;

export const Home = () => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const currentLanguage = i18n.language;

  // FAKE PROPERTIES ONLY
  // const [properties] = useState<Property[]>(FAKE_PROPERTIES);
  // END

  // FETCH PROPERTIES

  const [properties, setProperties] = useState<Property[]>([]);
  const { data: fetchedProperties, isLoading } = useGetPropertiesQuery({
    locale: currentLanguage,
  });

  useEffect(() => {
    if (fetchedProperties?.data) {
      // TODO check bad imput
      const data = fetchedProperties.data as IInitialProperty[];
      const props = convertToProperties(randomMediaGenerator, data);

      // TODO fallback when api failed
      // FALLBACK on fetch number
      // while (props.length < PREFERED_PROPERTY_NUM) {
      //   props.push(getRandomProperty());
      // }

      setProperties(props);
    }
  }, [fetchedProperties]);
  // END

  return (
    <div
      style={{
        letterSpacing: isRTL ? "-1.3px" : "",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <>
        <Header>
          <Navbar />
        </Header>
        <Overview />
        <HeroImg />
        <FeaturedProperties properties={properties} />
        <PropertyTypesBlock properties={properties.reverse()} />
        <ForBuyers />
        <Testimonials properties={properties} />
        <ExploreTopListing properties={properties} />
        <GetInTouchBlock />
        <Footer />
        <FooterSocialMediaLine />
      </>
    </div>
  );
};
