import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ISideNavState {
    sideNavOpen: boolean;
}

const initialState: ISideNavState = {
    sideNavOpen: false,
}

export const sideNavOpenSlice = createSlice({
    name: 'sideNav',
    initialState,
    reducers: {
        setOpenSideNav(state) {
            state.sideNavOpen = true;
        },
        setCloseSideNav(state) {
            state.sideNavOpen = false;
        }
    }
})

const sideNavOpenState = (state: RootState) => state.sideNav;

export const sideNavOpenSelector = createSelector(
    sideNavOpenState,
    (s) => s.sideNavOpen
)

export const {
    setOpenSideNav,
    setCloseSideNav
} = sideNavOpenSlice.actions;

export default sideNavOpenSlice.reducer