import { Pagination, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  propertiesPaginationParamnsSelector,
  setFetchedProperties,
} from "../../store/features/portfolioSlice";
import { useLazyGetPropertiesTestQuery } from "../../store/api/propertyApiSlice";
import { useAppDispatch } from "../../store/store";
import { useTranslation } from "react-i18next";
import { convertToProperties } from "../../helpers/convert";
import { IInitialProperty } from "../../types/initialProperty";
import { randomMediaGenerator } from "../../helpers/fakeMedia";

const StyledPagination = styled(Pagination)(() => ({
  borderRadius: "5px",
  "& .MuiPaginationItem-root": {
    color: "rgba(51, 51, 51, 1)",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Frank Ruhl Libre",
    border: "1px solid rgba(241, 241, 241, 1)",
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    backgroundColor: "rgba(252, 188, 92, 1)",
    color: "white",
  },
  "& .MuiPaginationItem-page:hover": { backgroundColor: "lightgray" },
}));

export const PropertiesPagination = ({
  onPageChange,
}: {
  onPageChange: (pageNum: number) => void;
}) => {
  const dispatch = useAppDispatch();

  const [page, setPage] = React.useState(1);
  const paginationParams = useSelector(propertiesPaginationParamnsSelector);

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
    setPage(page);
  };

  const [trigger, { data: fetchedProperties }] =
    useLazyGetPropertiesTestQuery();

  React.useEffect(() => {
    if (fetchedProperties) {
      const data = fetchedProperties.data as IInitialProperty[];
      const props = convertToProperties(randomMediaGenerator, data);

      dispatch(setFetchedProperties(props));
    }
  }, [fetchedProperties]);

  React.useEffect(() => {
    setPage(paginationParams.pagination.page);
  }, [paginationParams]);

  if (!paginationParams || !paginationParams.pagination) {
    return null;
  }

  return (
    <StyledPagination
      count={paginationParams?.pagination?.pageCount}
      page={page}
      onChange={handleChange}
      showFirstButton
      showLastButton
    />
  );
};
