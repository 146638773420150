import React from "react";
import { useTranslation } from "react-i18next";
import { Theme, useMediaQuery } from "@mui/material";
import { HomeFilterUpMd } from "../HomeFilterUpMd";
import { HomeFilterDownMd } from "../HomeFilterDownMd";
import { HeroImgStatisticsUpMd } from "./HeroImgStatisticsUpMd";
import { HeroImgStatisticsDownMd } from "./HeroImgStatisticsDownMd";

export const HeroImg = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <div
      className="overview-hero-img-container"
      style={{
        direction: isRTL ? "rtl" : "ltr",
        height: upLg ? "650px" : downMd ? "317px" : "617px",
        maxWidth: "3000px",
      }}
    >
      {downMd ? <HomeFilterDownMd /> : <HomeFilterUpMd />}
      {downMd ? <HeroImgStatisticsDownMd /> : <HeroImgStatisticsUpMd />}
    </div>
  );
};
