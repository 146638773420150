import React, { useCallback, useEffect, useState } from "react";
import { FilterSideBarSelect } from "./FilterSideBarSelect";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store/store";
import {
  activePropertyFiltersSelector,
  defaultActiveFilters,
  setActivePropertiesFilter,
  setFilterSideBarClose,
} from "../../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  allLocations,
  allPropertyTypes,
  allStatuses,
  locations,
  propertyTypes,
  statuses,
} from "../../constants/propertyData";
import { IPropertyActiveFilters, IPropertySearchParamObj } from "../../types/properties";
import { PriceRangeSlider } from "../_common/PriceRangeSlider";

export const BuyTabPanelContent = ({
  clearTrigger,
}: {
  clearTrigger: number;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const activePropertyFilters = useSelector(activePropertyFiltersSelector);

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const paramsObject: Partial<IPropertySearchParamObj> = {};
    let found = false;

    searchParams.forEach((value, key) => {
      if (!paramsObject[key as keyof IPropertySearchParamObj]) {
        paramsObject[key as keyof IPropertySearchParamObj] = [];
      }
      if (!["page", "sort", "direction"].includes(key)) {
        found = true;
      }
      (paramsObject[key as keyof IPropertySearchParamObj] as string[]).push(
        value
      );
    });
    if (searchParams.size === 3) {
      if (!found) {
        dispatch(
          setActivePropertiesFilter({
            // location: allLocations,
            // propertyTypes: allPropertyTypes,
            priceRange: [0, 100_000_000],
            // statuses: allStatuses,
          })
        );
      }
    } else {
      const paramsObject: Partial<IPropertySearchParamObj> = {};

      searchParams.forEach((value, key) => {
        if (!paramsObject[key as keyof IPropertySearchParamObj]) {
          paramsObject[key as keyof IPropertySearchParamObj] = [];
        }
        (paramsObject[key as keyof IPropertySearchParamObj] as string[]).push(
          value
        );
      });

      dispatch(setActivePropertiesFilter(paramsObject));
    }
  }, [searchParams]);

  const [lActive, setLActive] =
    useState<IPropertyActiveFilters>(defaultActiveFilters);

  const handleFilterChange = (key: string, selectedOptions: string[]) => {
    setLActive((prev: IPropertyActiveFilters) => {
      return {
        ...prev,
        [key]: selectedOptions,
      };
    });
  };

  useEffect(() => {
    setLActive(activePropertyFilters);
  }, [activePropertyFilters]);

  const handleSearch = useCallback(() => {
    const params = new URLSearchParams();

    Object.entries(lActive).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((value) => params.append(key, value));
      }
    });

    params.delete("name");
    dispatch(setFilterSideBarClose());

    navigate(`/portfolio?${params.toString()}`);
  }, [lActive]);

  const [priceRangeValue, setPriceRangeValue] = React.useState<number[]>([
    0, 100_000_000,
  ]);

  const handleChangePriceRange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setPriceRangeValue(newValue as number[]);
    setLActive((prev: IPropertyActiveFilters) => {
      return {
        ...prev,
        ["priceRange"]: newValue as number[],
      };
    });
  };

  return (
    <div className="filter-side-bar-filters-container">
      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("location")}
        </span>
        <FilterSideBarSelect
          options={locations}
          fontFamily={isRTL ? "Alef" : "DM Sans"}
          truncateLength={40}
          clearTrigger={clearTrigger}
          selectedOptions={lActive.location}
          onChange={(selected: string[]) =>
            handleFilterChange("location", selected)
          }
        />
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("property_type")}
        </span>
        <FilterSideBarSelect
          options={propertyTypes}
          fontFamily={isRTL ? "Alef" : "DM Sans"}
          truncateLength={40}
          clearTrigger={clearTrigger}
          selectedOptions={lActive.propertyTypes}
          onChange={(selected: string[]) =>
            handleFilterChange("propertyTypes", selected)
          }
        />
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("price_range")}
        </span>
        <div
          style={{
            padding: "0 10px",
          }}
        >
          <PriceRangeSlider
            priceRangeValue={lActive.priceRange}
            handleChange={handleChangePriceRange}
            styles={{ color: "rgba(0, 0, 0, 0.63)" }}
          />
        </div>
      </div>

      <div>
        <span
          className="filter-side-bar-filters-title"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("status")}
        </span>
        <FilterSideBarSelect
          options={statuses}
          fontFamily={isRTL ? "Alef" : "DM Sans"}
          truncateLength={40}
          clearTrigger={clearTrigger}
          selectedOptions={lActive.statuses}
          onChange={(selected: string[]) =>
            handleFilterChange("statuses", selected)
          }
        />
      </div>

      <button
        onClick={() => handleSearch()}
        className="filter-side-bar-search-btn"
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
          cursor: "pointer",
        }}
      >
        {t("search")}
      </button>
    </div>
  );
};
