import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Property } from "../../helpers/convert";
import {
  IPropertyActiveFilters,
  IPropertySearchParamObj,
} from "../../types/properties";

export interface IPropertiesPaginationParams {
  pagination: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  };
}

export interface IPropertyErrorResponseDataError {
  status: number;
  name: string;
  message: string;
}

export interface IPropertyErrorResponseData {
  error: IPropertyErrorResponseDataError;
}

export interface IPropertyErrorResponse {
  status: number;
  data: IPropertyErrorResponseData;
}

export interface IPortfolioSliceState {
  mapChecked: boolean;
  filterSideBarOpen: boolean;
  mapSideBarOpen: boolean;
  activePropertyFilters: IPropertyActiveFilters;
  fetchedProperties: Property[];
  propertiesPaginationParams: IPropertiesPaginationParams;
  propertyError: IPropertyErrorResponse | string;
}

export const defaultActiveFilters: IPropertyActiveFilters = {
  location: [],
  propertyTypes: [],
  priceRanges: [],
  priceRange: [],
  statuses: [],
};

const initialState: IPortfolioSliceState = {
  mapChecked: false,
  filterSideBarOpen: false,
  mapSideBarOpen: false,
  activePropertyFilters: defaultActiveFilters,
  fetchedProperties: [],
  propertiesPaginationParams: {
    pagination: { page: 0, pageCount: 0, pageSize: 0, total: 0 },
  },
  propertyError: "",
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setMapChecked(state, action) {
      state.mapChecked = action.payload;
    },
    setFilterSideBarOpen(state) {
      state.filterSideBarOpen = true;
    },
    setFilterSideBarClose(state) {
      state.filterSideBarOpen = false;
    },

    setMapSideBarOpen(state) {
      state.mapSideBarOpen = true;
    },
    setMapSideBarClose(state) {
      state.mapSideBarOpen = false;
    },
    setActivePropertiesFilter(state, action) {
      state.activePropertyFilters = action.payload;
    },
    setFetchedProperties(state, action: PayloadAction<Property[]>) {
      state.fetchedProperties = action.payload;
    },
    setPropertiesPaginationParams(
      state,
      action: PayloadAction<IPropertiesPaginationParams>
    ) {
      state.propertiesPaginationParams = action.payload;
    },
    setPropertyError(state, action) {
      state.propertyError = action.payload.propertyError;
    },
  },
});

const createPortfolioState = (state: RootState) => state.portfolio;

export const mapCheckedSelector = createSelector(
  createPortfolioState,
  (s) => s.mapChecked
);

export const filterSideBarOpenSelector = createSelector(
  createPortfolioState,
  (s) => s.filterSideBarOpen
);

export const mapSideBarOpenSelector = createSelector(
  createPortfolioState,
  (s) => s.mapSideBarOpen
);

export const activePropertyFiltersSelector = createSelector(
  createPortfolioState,
  (s) => s.activePropertyFilters
);

export const fetchedPropertiesSelector = createSelector(
  createPortfolioState,
  (s) => s.fetchedProperties
);

export const propertiesPaginationParamnsSelector = createSelector(
  createPortfolioState,
  (s) => s.propertiesPaginationParams
);

export const propertyErrorSelector = createSelector(
  createPortfolioState,
  (s) => s.propertyError
);

export const {
  setMapChecked,
  setFilterSideBarClose,
  setFilterSideBarOpen,
  setMapSideBarClose,
  setMapSideBarOpen,
  setActivePropertiesFilter,
  setFetchedProperties,
  setPropertiesPaginationParams,
  setPropertyError,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
