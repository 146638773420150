import React from "react";
import ForBuyersImg from "../../../assets/for_buyers_img.png";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ForBuyersDownMdImgBlock = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "115px",
          left: 0,
          maxWidth: "158px",
          display: "flex",
          flexWrap: "wrap",
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {isRTL ? (
          <Typography
            variant="h4"
            className="for-buyers-black-text"
            style={{
              direction: "rtl",
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("for_buyers_text1")}
          </Typography>
        ) : (
          <>
            <Typography variant="h4" className="for-buyers-black-text">
              {t("curated_selection_of")}
            </Typography>
            <Typography variant="h4" className="for-buyers-blue-text">
              {t("luxury")}
              <span>&nbsp;</span>
            </Typography>
            <Typography variant="h4" className="for-buyers-blue-text">
              {t("properties")}
            </Typography>
            <Typography variant="h4" className="for-buyers-black-text">
              {t("in_prime_locations")}
            </Typography>
          </>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          top: "195px",
          left: "90px",
          width: "2px",
          height: "90px",
          backgroundColor: "#000",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-6px",
            left: "-3px",
            width: "9px",
            height: "9px",
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        ></div>
      </div>

      <div
        style={{
          position: "absolute",
          top: "45px",
          right: "0",
          maxWidth: "175px",
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {isRTL ? (
          <Typography
            variant="h4"
            className="for-buyers-black-text"
            style={{
              direction: "rtl",
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("for_buyers_text2")}
          </Typography>
        ) : (
          <>
            <Typography variant="h4" className="for-buyers-blue-text">
              {t("personalized_property")}
            </Typography>
            <Typography variant="h4" className="for-buyers-black-text">
              {t("matching_based_on")}
            </Typography>
          </>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          top: "130px",
          right: "90px",
          width: "2px",
          height: "157px",
          backgroundColor: "#000",
          fontFamily: isRTL ? "Alef" : "DM Sans",

        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-6px",
            left: "-3px",
            width: "9px",
            height: "9px",
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        ></div>
      </div>

      <div
        className="for-buyers-img-down-md"
        style={{
          zIndex: 1,
          width: "100%",
          height: "320px",
          backgroundSize: "auto",
          background: `url(${ForBuyersImg}) center center / cover no-repeat`,
          borderRadius: "20px",
          marginTop: "270px",
          marginBottom: "290px",
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          bottom: "63px",
          left: 0,
          maxWidth: "158px",
          display: "flex",
          flexWrap: "wrap",
          fontFamily: isRTL ? "Alef" : "DM Sans",

        }}
      >
        {isRTL ? (
          <Typography
            variant="h4"
            className="for-buyers-black-text"
            style={{
              direction: "rtl",
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("for_buyers_text4")}
          </Typography>
        ) : (
          <>
            <Typography variant="h4" className="for-buyers-black-text">
              {t("exclusive_access_to")}
            </Typography>{" "}
            <Typography variant="h4" className="for-buyers-blue-text">
              {t("pre_launch_projects")}
            </Typography>{" "}
            <Typography variant="h4" className="for-buyers-black-text">
              {t("and")}
            </Typography>{" "}
            <span>&nbsp;</span>
            <Typography variant="h4" className="for-buyers-blue-text">
              {t("VIP_events")}
            </Typography>{" "}
          </>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "155px",
          left: "90px",
          width: "2px",
          height: "140px",
          backgroundColor: "#000",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-6px",
            left: "-3px",
            width: "9px",
            height: "9px",
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        ></div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "155px",
          right: "0",
          maxWidth: "175px",
          display: "flex",
          flexWrap: "wrap",
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {isRTL ? (
          <Typography
            variant="h4"
            className="for-buyers-black-text"
            style={{
              direction: "rtl",
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("for_buyers_text3")}
          </Typography>
        ) : (
          <>
            <Typography variant="h4" className="for-buyers-blue-text">
              {t("expert_guidance")}
            </Typography>{" "}
            <Typography variant="h4" className="for-buyers-black-text">
              {t("throughout_your_investment_journey")}
            </Typography>{" "}
          </>
        )}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "245px",
          right: "90px",
          width: "2px",
          height: "60px",
          backgroundColor: "#000",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-6px",
            left: "-3px",
            width: "9px",
            height: "9px",
            backgroundColor: "#000",
            borderRadius: "50%",
          }}
        ></div>
      </div>
    </div>
  );
};
