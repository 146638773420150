import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FingerPrint from "../../assets/icons/fingerprint.svg";
import { TextField } from "@mui/material";
import { BackToLoginBtn } from "./BackToLoginBtn";
import { ResetPasswordBtn } from "./ResetPasswordBtn";
import { authTextFieldStyles } from "../../pages/Signup";

export const PasswordResetEnterEmailStep = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <div className="forgot-p-container">
      <div className="forgot-p-fingerprint-container">
        <img src={FingerPrint} />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
        }}
      >
        {t("forget_password")}
      </div>

      <div
        className="forgot-p-desc"
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {t("forgot_p_no_worries")}
      </div>

      <div
        style={{
          width: "100%",
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div
          className="signin-form-inputs-label"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("email")}
        </div>
        <TextField
          type="email"
          fullWidth
          required
          placeholder={t("email_placeholder")}
          sx={(theme) => authTextFieldStyles.textField(theme, isRTL)}
        />
      </div>

      <ResetPasswordBtn onSubmit={onSubmit} />
      <BackToLoginBtn />
    </div>
  );
};
