import { Switch, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {
  mapCheckedSelector,
  setMapChecked,
} from "../../../store/features/portfolioSlice";
import { useAppDispatch } from "../../../store/store";

const CustomSwitch = styled(Switch)(() => ({
  width: "46px",
  height: "25px",
  padding: 3,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(23px)",
      color: "rgba(3, 74, 115, 1)",
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(3, 74, 115, 1)",
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: "12px",
    height: "14px",
    width: "34px",
    backgroundColor: "#bdbdbd",
    opacity: 1,
  },
  "& .MuiSwitch-thumb": {
    width: "20px",
    height: "20px",
    boxShadow: "none",
  },
}));

export const MapSwither = () => {
  const { t } = useTranslation(["portfolio"]);
  const { i18n, ready } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const dispatch = useAppDispatch();
  const mapChecked = useSelector(mapCheckedSelector);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMapChecked(event.target.checked));
  };

  useEffect(() => {
    dispatch(setMapChecked(false));
  }, [i18n.language]);

  return (
    <div className="portfolio-map-switcher-container">
      <div
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
          marginBottom: "10px",
          fontSize: "14px",
          fontWeight: 500,
          marginRight: "15px",
        }}
      >
        {t("map")}
      </div>

      <div
        style={{
          marginRight: "15px",
        }}
      >
        <CustomSwitch onChange={handleChange} checked={mapChecked} />
      </div>
    </div>
  );
};
