import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const DemoLogin = () => {
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const correctPassword = "t9&Xs4#bW2!zR8^q";

  const handleCheckPassword = () => {
    if (password === correctPassword) {
      localStorage.setItem("isAuthenticated", "true");

      navigate("/home");
    } else {
      setError("Wrong password");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError("");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "80vh",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <TextField
        placeholder="Enter password"
        value={password}
        onChange={handleInputChange}
      />

      <Box
        onClick={handleCheckPassword}
        sx={{
          display: "flex",
          alignItems: "baseline",
          color: "#fff",
          width: "fit-content",
          backgroundColor: "#034A73",
          p: "15px 30px 15px 30px",
          cursor: "pointer",
          borderRadius: "24px",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontFamily: "DM Sans",
            lineHeight: "16px",
          }}
        >
          Login
        </Typography>
      </Box>

      {error && <div>{error}</div>}
    </div>
  );
};
