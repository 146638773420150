import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import { setCloseSideNav, sideNavOpenSelector } from "../../store/features/common";
import { SideNavMenu } from "./SideNavMenu";

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
}));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  alignItems: "center",
});

export const Layout = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const openNav = useSelector(sideNavOpenSelector);

  const handlePathnameChange = React.useCallback(() => {
    if (openNav) {
      dispatch(setCloseSideNav());
    }
  }, [openNav]);

  React.useEffect(() => {
    handlePathnameChange();
  }, [location]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <LayoutRoot>
          <LayoutContainer>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SideNavMenu />
              <Outlet></Outlet>
            </Box>
          </LayoutContainer>
        </LayoutRoot>
      </Box>
    </>
  );
};
