import React from "react";
import { useTranslation } from "react-i18next";

export const ResetPasswordBtn = ({ onSubmit }: { onSubmit: () => void }) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  return (
    <button
      onClick={onSubmit}
      className="signin-form-inputs-signin-btn"
      style={{
        fontFamily: isRTL ? "Alef" : "DM Sans",
        fontWeight: isRTL ? 700 : 500,
      }}
    >
      {t("reset_password")}
    </button>
  );
};
