import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { getRandomStamp, IStamp } from "../../helpers/formatter";

export const PropertyCardStamp = () => {
  const { t } = useTranslation(["portfolio"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const [randomStamp, setRandomStamp] = useState<IStamp | null>(null);

  useEffect(() => {
    setRandomStamp(getRandomStamp());
  }, []);

  return (
    <>
      {!randomStamp ? (
        ""
      ) : (
        <>
          {isRTL ? (
            <div
              style={{
                position: "absolute",
                top: "-17px",
                right: "-9px",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <img src={randomStamp.imgRtl} />
                <div
                  style={{
                    position: "absolute",
                    top: "9px",
                    right: "19.5px",
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      fontFamily: isRTL ? "Alef" : "DM Sans",
                      color: "rgba(255, 255, 255, 1)",
                      direction: "rtl",
                    }}
                  >
                    {t(randomStamp.name)}
                  </div>
                  <img src={randomStamp.icon} />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "-17px",
                left: "-9px",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <img src={randomStamp.imgLtr} />
                <div
                  style={{
                    position: "absolute",
                    top: "9px",
                    left: "19.5px",
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  <img src={randomStamp.icon} />

                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      fontFamily: isRTL ? "Alef" : "DM Sans",
                      color: "rgba(255, 255, 255, 1)",
                    }}
                  >
                    {t(randomStamp.name)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
