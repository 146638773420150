import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { ReactComponent as SelectArrowBlack } from "../../assets/icons/selectArrowBlack.svg";
import { ReactComponent as SelectArrow } from "../../assets/icons/select_arrow.svg";
import {
  FilterSelectProps,
  IOptionWithText,
  OptionType,
} from "../../types/properties";
import { renderValueWithTrancateLength } from "../../helpers/dataConverts";
import { useAppDispatch } from "../../store/store";

interface CustomSelectProps<T extends OptionType> extends FilterSelectProps<T> {
  styles: {
    select: {
      color: string;
      iconComponent: React.ElementType;
    };
    menuItem: {
      color: string;
      backgroundColor: string;
      hoverBackground: string;
    };
    menuPaperBackground: string;
  };
}

export const ALL = "all";

export const CustomFilterSelect = <T extends OptionType>({
  options,
  fontFamily,
  truncateLength,
  selectedOptions = [],
  onChange,
  styles,
}: CustomSelectProps<T>) => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const optionsWithText = Object.keys(options).map((key) => {
    const option = options[Number(key)];
    return { id: option.id, text: isRTL ? option.valueHe : option.valueEn };
  });

  const handleChangeOption = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];

    if (value.includes(ALL)) {
      const allIds = optionsWithText.map((item) => item.id.toString());
      let eq = false;

      if (allIds.length == selectedOptions.length) {
        eq = true;
        for (let i = 0; i < allIds.length; i++) {
          if (allIds[i] != selectedOptions[i]) {
            eq = false;
            break;
          }
        }
      }

      if (eq) {
        onChange([]);
      } else {
        onChange(allIds);
      }
    } else {
      onChange(value);
    }
  };

  return (
    <Select
      multiple
      value={selectedOptions || []}
      onChange={handleChangeOption}
      renderValue={(selected) =>
        renderValueWithTrancateLength(selected, truncateLength, options, isRTL)
      }
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              background: styles.menuPaperBackground,
            },
          },
        },
      }}
      sx={{
        color: styles.select.color,
        fontFamily: fontFamily,
        fontWeight: isRTL ? 400 : 500,
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-select": {
          padding: 0,
          display: "flex",
          alignItems: "center",
          paddingRight: isRTL ? "0px" : "24px",
          paddingLeft: isRTL ? "24px" : "0px",
        },
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.2em)",
          right: isRTL ? "auto" : "12px",
          left: isRTL ? "5px" : "auto",
        },
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
          {
            paddingRight: isRTL ? "14px" : "32px",
            paddingLeft: isRTL ? "24px" : "0",
          },
      }}
      IconComponent={styles.select.iconComponent}
    >
      <MenuItem
        value={ALL}
        sx={{
          color: styles.menuItem.color,
          backgroundColor: styles.menuItem.backgroundColor,
          fontFamily: fontFamily,
          fontWeight: isRTL ? 400 : 500,
          "&:hover": {
            backgroundColor: styles.menuItem.hoverBackground,
          },
        }}
      >
        {t("all")}
      </MenuItem>
      {optionsWithText.map((option: IOptionWithText) => (
        <MenuItem
          value={option.id.toString()}
          key={option.id}
          sx={{
            color: styles.menuItem.color,
            backgroundColor: styles.menuItem.backgroundColor,
            fontFamily: fontFamily,
            fontWeight: isRTL ? 400 : 500,
            "&:hover": {
              backgroundColor: styles.menuItem.hoverBackground,
            },
          }}
        >
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export const portfolioStyles = {
  select: {
    color: "rgba(0, 0, 0, 1)",
    iconComponent: SelectArrowBlack,
  },
  menuItem: {
    color: "rgba(0, 0, 0, 1)",
    backgroundColor: "rgba(255, 255, 255, 0.82)",
    hoverBackground: "rgba(255, 255, 255, 1)",
  },
  menuPaperBackground: "rgba(255, 255, 255, 0.82)",
};

export const homeStyles = {
  select: {
    color: "#fff",
    iconComponent: SelectArrow,
  },
  menuItem: {
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.84)",
    hoverBackground: "rgba(0, 0, 0, 0.6)",
  },
  menuPaperBackground: "rgba(0, 0, 0, 0.84)",
};
