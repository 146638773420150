import { IPriceRanges } from "../types/properties";
import main1 from "../assets/fake/main/1.jpg";
import main3 from "../assets/fake/main/3.jpg";
import main4 from "../assets/fake/main/4.jpg";
import main5 from "../assets/fake/main/5.jpg";
import main6 from "../assets/fake/main/6.jpg";

export const locations = {
  0: { id: 0, valueEn: "Tel Aviv", valueHe: "תל אביב" },
  1: { id: 1, valueEn: "Jerusalem", valueHe: "ירושלים" },
  2: { id: 2, valueEn: "Haifa", valueHe: "חיפה" },
  3: { id: 3, valueEn: "Eilat", valueHe: "אילת" },
  4: { id: 4, valueEn: "Rishon LeZion", valueHe: "ראשון לציון" },
  5: { id: 5, valueEn: "Herzliya", valueHe: "הרצליה" },
  6: { id: 6, valueEn: "Ramat Gan", valueHe: "רמת גן" },
};

export const allLocations = Object.keys(locations).map((key) => Number(key));

export const propertyTypes = {
  0: { id: 0, valueEn: "Penthouse", valueHe: "פנטהאוז" },
  1: { id: 1, valueEn: "Garden Apartment", valueHe: "דירת גן" },
  2: { id: 2, valueEn: "Duplex", valueHe: "דופלקס" },
  3: { id: 3, valueEn: "Triplex", valueHe: "טריפלקס" },
  4: { id: 4, valueEn: "Villa/House", valueHe: "וילה/בית" },
  5: { id: 5, valueEn: "Townhouse", valueHe: "בית עירוני" },
  6: { id: 6, valueEn: "Rooftop Apartment", valueHe: "דירת גג" },
  7: {
    id: 7,
    valueEn: "Studio Apartment (Premium)",
    valueHe: "דירת סטודיו (פרימיום)",
  },
  8: { id: 8, valueEn: "Office Space", valueHe: "שטח משרדים" },
  9: { id: 9, valueEn: "Retail Space", valueHe: "שטח מסחרי" },
  10: { id: 10, valueEn: "Mixed-Use Development", valueHe: "פיתוח משולב" },
  11: { id: 11, valueEn: "Commercial Building", valueHe: "בניין מסחרי" },
  12: { id: 12, valueEn: "Business Center", valueHe: "מרכז עסקים" },
  13: { id: 13, valueEn: "Luxury Apartment", valueHe: "דירת יוקרה" },
};

export const allPropertyTypes = Object.keys(propertyTypes).map((key) =>
  Number(key)
);

export const priceRanges: { [key: number]: IPriceRanges } = {
  0: {
    id: 0,
    valueEn: "$1M-5M",
    valueHe: "₪1M-5M",
    min: 1000000,
    max: 5000000,
  },
  1: {
    id: 1,
    valueEn: "$5M-10M",
    valueHe: "₪5M-10M",
    min: 5000000,
    max: 10000000,
  },
  2: {
    id: 2,
    valueEn: "$10M-15M",
    valueHe: "₪10M-15M",
    min: 10000000,
    max: 15000000,
  },
  3: {
    id: 3,
    valueEn: "$15M-20M",
    valueHe: "₪15M-20M",
    min: 15000000,
    max: 20000000,
  },
  4: {
    id: 4,
    valueEn: "$20M-25M",
    valueHe: "₪20M-25M",
    min: 20000000,
    max: 25000000,
  },
  5: {
    id: 5,
    valueEn: "$25M-30M",
    valueHe: "₪25M-30M",
    min: 25000000,
    max: 30000000,
  },
  6: {
    id: 6,
    valueEn: "$30M-35M",
    valueHe: "₪30M-35M",
    min: 30000000,
    max: 35000000,
  },
  7: {
    id: 7,
    valueEn: "$35M-40M",
    valueHe: "₪35M-40M",
    min: 35000000,
    max: 40000000,
  },
  8: {
    id: 8,
    valueEn: "$40M-45M",
    valueHe: "₪40M-45M",
    min: 40000000,
    max: 45000000,
  },
  9: {
    id: 9,
    valueEn: "$45M-50M",
    valueHe: "₪45M-50M",
    min: 45000000,
    max: 50000000,
  },
};

export const statuses = {
  0: {
    id: 0,
    valueEn: `Pre-Sale ("On Paper")`,
    valueHe: `לפני מכירה (״על הנייר״)`,
  },
  1: { id: 1, valueEn: "Under Construction", valueHe: " בבנייה" },
  2: { id: 2, valueEn: "Ready for Occupancy", valueHe: "מוכן לאכלוס" },
  3: { id: 3, valueEn: "Immediate Availability", valueHe: "זמינות מיידית" },
  4: { id: 4, valueEn: "Coming Soon", valueHe: "בקרוב" },
};

export const allStatuses = Object.keys(statuses).map((key) => Number(key));

export const sortOptions = [
  {
    id: 0,
    valueEn: "the newest",
    valueHe: "החדש ביותר",
    sortDirection: "asc",
    nameSortParam: "created_at",
    addParam: "",
  },
  {
    id: 1,
    valueEn: "by price (low to high)",
    valueHe: "לפי מחיר (מהנמוך לגבוה)",
    sortDirection: "asc",
    nameSortParam: "pricing",
    addParam: "basePrice",
  },
  {
    id: 2,
    valueEn: "by price (high to low)",
    valueHe: "לפי מחיר (מהגבוה לנמוך)",
    sortDirection: "desc",
    nameSortParam: "pricing",
    addParam: "basePrice",
  },
  {
    id: 3,
    valueEn: "by area (low to high)",
    valueHe: "לפי שטח (מהנמוך לגבוה)",
    sortDirection: "asc",
    nameSortParam: "details",
    addParam: "totalArea",
  },
  {
    id: 4,
    valueEn: "by area (high to low)",
    valueHe: "לפי שטח (מהגבוה לנמוך)",
    sortDirection: "desc",
    nameSortParam: "details",
    addParam: "totalArea",
  },
];

export const testimonials = [
  {
    img: main1,
    nameEn: "Melanie Grossman",
    occupationEn: "Buyer",
    testimonialEn:
      "From the first acquaintance with the object to the registration of the transaction, everything was at the highest level. Now we are enjoying a new home that perfectly meets our expectations!",
    nameHe: "מלאני גרוסמן",
    occupationHe: "קונה",
    testimonialHe:
      "מההיכרות הראשונה עם הנכס ועד לרישום העסקה, הכל היה ברמה הגבוהה ביותר. כעת אנו נהנים מבית חדש שעונה בצורה מושלמת על הציפיות שלנו!",
  },
  {
    img: main3,

    nameEn: "David Cohen",
    occupationEn: "Investor",
    testimonialEn:
      "The process was smooth and efficient. The team understood our needs perfectly and helped us find the ideal investment property.",
    nameHe: "דוד כהן",
    occupationHe: "משקיע",
    testimonialHe:
      "התהליך היה חלק ויעיל. הצוות הבין את הצרכים שלנו בצורה מושלמת ועזר לנו למצוא את הנכס להשקעה האידאלי.",
  },
  {
    img: main4,

    nameEn: "Sara Levi",
    occupationEn: "Homeowner",
    testimonialEn:
      "Excellent service from start to finish. The team was very professional and guided us through the entire process with ease.",
    nameHe: "שרה לוי",
    occupationHe: "בעלת בית",
    testimonialHe:
      "שירות מצוין מהתחלה ועד הסוף. הצוות היה מאוד מקצועי והנחה אותנו בכל התהליך בקלות.",
  },
  {
    img: main5,

    nameEn: "Michael Rosen",
    occupationEn: "Renter",
    testimonialEn:
      "I was impressed by the level of dedication and attention to detail. They found the perfect rental property for us!",
    nameHe: "מיכאל רוזן",
    occupationHe: "שוכר",
    testimonialHe:
      "התרשמתי מרמת ההשקעה ותשומת הלב לפרטים. הם מצאו את הנכס להשכרה המושלם עבורנו!",
  },
  {
    img: main6,

    nameEn: "Lea Abramov",
    occupationEn: "Seller",
    testimonialEn:
      "The sale process was handled with great expertise and professionalism. I highly recommend their services.",
    nameHe: "לאה אברמוב",
    occupationHe: "מוכרת",
    testimonialHe:
      "תהליך המכירה טופל במיומנות ומקצועיות רבה. אני ממליצה בחום על השירותים שלהם.",
  },
];
