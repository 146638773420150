import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/arrow.svg";

export const ConsultationInfo = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";

  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return (
    <div
      className="consultation-info-conatiner"
      style={{ direction: isRTL ? "rtl" : "ltr" }}
    >
      <Typography
        variant="h3"
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {t("consultation_sub_title")}
      </Typography>
      <Typography
        variant="h2"
        sx={{
          lineHeight: downMd ? "28px" : "46px",
          mb: downMd ? "10px" : "15px",
          mt: downMd ? "9px" : "5px",
          fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
        }}
      >
        {t("consultation_title")}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          mb: downMd ? "15px" : "45px",
          lineHeight: downMd ? "20px" : "22px",
          letterSpacing: "0.64px",
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {t("consultation_desc")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          color: "#fff",
          width: "fit-content",
          backgroundColor: "rgba(215, 161, 72, 1)",
          p: "15px 30px 15px 30px",
          cursor: "pointer",
          borderRadius: "24px",
          mb: downLg ? "30px" : 0,
          transition: "background 2s ease-in-out .5s",
          "&:hover": {
            background:
              "linear-gradient(to right, rgba(215, 161, 72, 1), rgba(255, 225, 78, 1))",
          },
        }}
      >
        <Typography
          sx={{
            mr: isRTL ? "0" : "12px",
            ml: isRTL ? "12px" : "0",
            fontFamily: isRTL ? "Alef" : "DM Sans",
            fontWeight: isRTL ? 600 : 500,
            lineHeight: "16px",
          }}
        >
          {t("consultation_btn_text")}
        </Typography>
        <img
          src={Arrow}
          alt="arrow"
          style={{
            transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
          }}
        />
      </Box>
    </div>
  );
};
