import React, { useCallback, useEffect, useState } from "react";
import Sparkles from "../../assets/sparkles.png";
import { useTranslation } from "react-i18next";
import { CustomFilterSelect, homeStyles } from "../_common/CustomFilterSelect";
import SearchIcon from "../../assets/icons/search_icon.svg";
import { Theme, useMediaQuery } from "@mui/material";
import { useAppDispatch } from "../../store/store";
import {
  activePropertyFiltersSelector,
  defaultActiveFilters,
  setActivePropertiesFilter,
} from "../../store/features/portfolioSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  allLocations,
  allPropertyTypes,
  allStatuses,
  locations,
  priceRanges,
  propertyTypes,
  statuses,
} from "../../constants/propertyData";
import { IPropertyActiveFilters } from "../../types/properties";
import { AskAIPopoverBlack } from "./AskAIPopover";
import { SearchTab } from "../portfolio/PortfolioFilterUpMd";
import { PriceRangeSlider } from "../_common/PriceRangeSlider";
import SelectArrow from "../../assets/icons/select_arrow.svg";
import { formatPrice } from "../../helpers/formatter";

export const HomeFilterUpMd = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";
  const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  const mainContainerRef = React.useRef<HTMLDivElement | null>(null);

  const handleOpenAskAIPopover = (event: React.MouseEvent<HTMLElement>) => {
    setSearchTab(SearchTab.search);
  };

  const activePropertyFilters = useSelector(activePropertyFiltersSelector);

  React.useEffect(() => {
    dispatch(
      setActivePropertiesFilter({
        // location: [locations[0].id.toString()],
        // propertyTypes: [propertyTypes[0].id.toString()],
        // priceRange: [0, 100_000_000],
        // statuses: [statuses[0].id.toString()],
      })
    );
  }, []);

  const [lActive, setLActive] =
    useState<IPropertyActiveFilters>(defaultActiveFilters);

  const handleFilterChange = (key: string, selectedOptions: string[]) => {
    setLActive((prev: IPropertyActiveFilters) => {
      return {
        ...prev,
        [key]: selectedOptions,
      };
    });
  };

  useEffect(() => {
    setLActive(activePropertyFilters);
  }, [activePropertyFilters]);

  React.useEffect(() => {
    dispatch(
      setActivePropertiesFilter({
        // location: [locations[0].id.toString()],
        // propertyTypes: [propertyTypes[0].id.toString()],
        // priceRange: [0, 100_000_000],
        // statuses: [statuses[0].id.toString()],
      })
    );
  }, [currentLanguage]);

  const handleNavigateToPortfolioPage = useCallback(() => {
    const params = new URLSearchParams();
    Object.entries(lActive).forEach(([key, values]) => {
      if (Array.isArray(values)) {
        values.forEach((value) => params.append(key, value));
      }
    });

    navigate(`/portfolio?${params.toString()}`);
  }, [lActive]);

  const handleClearFilters = () => {
    dispatch(
      setActivePropertiesFilter({
        // location: allLocations,
        // propertyTypes: allPropertyTypes,
        // priceRange: [0, 100_000_000],
        // statuses: allStatuses,
      })
    );
  };

  const [searchTab, setSearchTab] = React.useState(SearchTab.filter);

  const handleOpenFilter = () => {
    setSearchTab(SearchTab.filter);
  };

  const [priceRangeValue, setPriceRangeValue] = React.useState<number[]>([
    0, 100_000_000,
  ]);

  const handleChangePriceRange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setPriceRangeValue(newValue as number[]);
    setLActive((prev: IPropertyActiveFilters) => {
      return {
        ...prev,
        ["priceRange"]: newValue as number[],
      };
    });
  };

  const [openPriceRangePanel, setOpenPriceRangePanel] = React.useState(false);
  const panelRef = React.useRef<HTMLDivElement>(null);

  const handleOpenPriceRangePanel = () => {
    setOpenPriceRangePanel(!openPriceRangePanel);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setOpenPriceRangePanel(false);
    }
  };

  useEffect(() => {
    if (openPriceRangePanel) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPriceRangePanel]);

  return (
    <div className="xl-width" style={{ position: "relative" }}>
      <div
        className="overview-filter-container"
        style={{
          right: isRTL ? "auto" : "50px",
          left: !isRTL ? "auto" : "35px",
        }}
      >
        <div
          className="overview-filter-main-filter-container"
          style={{ position: "relative" }}
          ref={mainContainerRef}
        >
          {searchTab === SearchTab.filter && (
            <div
              className="filter-ai-sparkles-container"
              onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                handleOpenAskAIPopover(e)
              }
            >
              <img
                src={Sparkles}
                alt="sparkles"
                style={{ width: "22px", height: "22px" }}
              />
              <p
                style={{
                  marginRight: isRTL ? "6px" : "0px",
                  marginLeft: isRTL ? "0" : "6px",
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                }}
              >
                {t("search")}
              </p>
            </div>
          )}

          {searchTab === SearchTab.search && (
            <div
              className="filter-ai-sparkles-container"
              onClick={handleOpenFilter}
            >
              <p
                style={{
                  marginRight: isRTL ? "6px" : "0px",
                  marginLeft: isRTL ? "0" : "6px",
                  marginBottom: 0,
                  marginTop: 0,
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                }}
              >
                {t("filters")}
              </p>
            </div>
          )}
        </div>

        <AskAIPopoverBlack searchTab={searchTab} />

        <div
          className="overview-filter-second-filter-container"
          style={{
            paddingRight: isRTL ? "40px" : "14px",
            paddingLeft: isRTL ? "14px" : "40px",
            display: searchTab === SearchTab.filter ? "flex" : "none",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={`filter-location-container ${
                isRTL ? "borderLeft" : "borderRight"
              } `}
              style={{
                borderRight: isRTL ? "none" : "1px solid #FFFFFF85",
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "120px" : "85px",
              }}
            >
              <span>{t("location")}</span>
              <CustomFilterSelect
                options={locations}
                fontFamily={isRTL ? "Alef" : "DM Sans"}
                truncateLength={downLg ? 8 : 12}
                selectedOptions={lActive.location}
                onChange={(selected: string[]) =>
                  handleFilterChange("location", selected)
                }
                styles={homeStyles}
              />
            </div>

            <div
              className="filter-property-type-container"
              style={{
                borderRight: "1px solid #FFFFFF85",
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "138px" : "109px",
              }}
            >
              <span>{t("property_type")}</span>
              <CustomFilterSelect
                options={propertyTypes}
                fontFamily={isRTL ? "Alef" : "DM Sans"}
                truncateLength={downLg ? 8 : 14}
                selectedOptions={lActive.propertyTypes}
                onChange={(selected: string[]) =>
                  handleFilterChange("propertyTypes", selected)
                }
                styles={homeStyles}
              />
            </div>

            <div
              style={{
                marginRight: "15px",
                position: "relative",
              }}
              ref={panelRef}
            >
              <div
                onClick={handleOpenPriceRangePanel}
                style={{
                  width: "120px",
                  height: "46px",
                  borderRight: "1px solid rgba(176, 155, 155, 0.52)",
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: isRTL ? "flex-start" : "baseline",
                  gap: lActive.priceRange ? "0" : "14px",
                }}
              >
                <span
                  style={{
                    color: "rgba(255, 255, 255, 0.77)",
                    fontSize: "14px",
                    marginRight: "15px",
                  }}
                >
                  {t("price_range")}
                </span>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    color: "rgba(255, 255, 255, 1)",
                    width: "100%",
                    justifyContent: lActive.priceRange ? "left" : "center",
                    gap: "3px",
                  }}
                >
                  {lActive.priceRange && (
                    <>
                      {isRTL ? "₪" : "$"}

                      <div>{`${formatPrice(
                        lActive.priceRange[0]
                      )}-${formatPrice(lActive.priceRange[1])}`}</div>
                    </>
                  )}

                  <img src={SelectArrow} />
                </div>
              </div>

              <div
                className="portfolio-filter-price-range-container-test"
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: isRTL ? 400 : 500,
                  position: "absolute",
                  top: "80px",
                  borderRadius: "45px",
                  padding: "5px 35px",
                  backgroundColor: "rgba(0, 0, 0, 0.84)",
                  width: "320px",
                  display: openPriceRangePanel ? "block" : "none",
                }}
              >
                <PriceRangeSlider
                  priceRangeValue={priceRangeValue}
                  handleChange={handleChangePriceRange}
                  styles={{ color: "white" }}
                />
              </div>
            </div>

            <div
              className="filter-status-container"
              style={{
                borderRight: isRTL ? "1px solid #FFFFFF85" : "none",
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 400 : 500,
                width: upLg ? "100px" : "90px",
              }}
            >
              <span>{t("status")}</span>
              <CustomFilterSelect
                options={statuses}
                fontFamily={isRTL ? "Alef" : "DM Sans"}
                truncateLength={downLg ? 8 : 13}
                selectedOptions={lActive.statuses}
                onChange={(selected: string[]) =>
                  handleFilterChange("statuses", selected)
                }
                styles={homeStyles}
              />
            </div>

            <div
              onClick={handleClearFilters}
              style={{
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 400 : 500,
                cursor: "pointer",
                color: "white",
              }}
            >
              {t("clear")}
            </div>
          </div>

          <div
            className="overview-filter-second-search-btn-container"
            onClick={() => handleNavigateToPortfolioPage()}
          >
            <img src={SearchIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};
