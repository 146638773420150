import React from "react";
import Lock from "../../assets/icons/lock.svg";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "../../assets/icons/visibility.svg";
import VisibilityOff from "../../assets/icons/visibility_off.svg";
import { BackToLoginBtn } from "./BackToLoginBtn";
import { ResetPasswordBtn } from "./ResetPasswordBtn";
import { PasswordCheck } from "./PasswordCheck";

export const PasswordResetNewPasswordStep = ({
  onSubmit,
}: {
  onSubmit: () => void;
}) => {
  const { t } = useTranslation(["signin"]);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === "he";

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const isAtLeast8Chars = password.length >= 8;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasNumber = /\d/.test(password);

  return (
    <div className="forgot-p-container">
      <div className="forgot-p-lock-container">
        <img src={Lock} />
      </div>

      <div
        className="forgot-p-title"
        style={{
          fontFamily: isRTL ? "Alef" : "Frank Ruhl Libre",
        }}
      >
        {t("set_an_new_password_title")}
      </div>
      <div
        className="forgot-p-desc"
        style={{
          fontFamily: isRTL ? "Alef" : "DM Sans",
        }}
      >
        {t("set_a_new_password_desc")}
      </div>

      <div style={{ width: "100%", direction: isRTL ? "rtl" : "ltr" }}>
        <div
          className="signin-form-inputs-label"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("password")}
        </div>
        <TextField
          type={showPassword ? "text" : "password"}
          fullWidth
          required
          placeholder="Enter password"
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <img
                      src={VisibilityOff}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                  ) : (
                    <img
                      src={Visibility}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              border: "1px solid rgba(224, 229, 242, 1)",
              borderRadius: "16px",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              border: "1px solid rgba(129, 154, 221, 1)",
            },
            "& .MuiOutlinedInput-input": {
              fontSize: "14px",
              color: "rgba(43, 54, 116, 1)",
              fontFamily: "DM Sans",
              fontWeight: 500,
              lineHeight: "14px",
              letterSpacing: "-0.32px",
            },
            "& .MuiOutlinedInput-input::placeholder": {
              color: "rgba(163, 174, 208, 1)",
              opacity: 1,
            },
            "& .MuiOutlinedInput-input:focus::placeholder": {
              opacity: 0,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
        <PasswordCheck
          isAtLeast8Chars={isAtLeast8Chars}
          hasSpecialChar={hasSpecialChar}
          hasNumber={hasNumber}
        />
        <div
          className="signin-form-inputs-label"
          style={{
            fontFamily: isRTL ? "Alef" : "DM Sans",
          }}
        >
          {t("confirm_password")}
        </div>
        <TextField
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
          required
          placeholder={t("confirm_password")}
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleToggleConfirmPasswordVisibility}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showConfirmPassword ? (
                    <img
                      src={VisibilityOff}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                  ) : (
                    <img
                      src={Visibility}
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "8px",
                      }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              border: "1px solid rgba(224, 229, 242, 1)",
              borderRadius: "16px",
            },
            "& .MuiOutlinedInput-root.Mui-focused": {
              border: "1px solid rgba(129, 154, 221, 1)",
            },
            "& .MuiOutlinedInput-input": {
              fontSize: "14px",
              color: "rgba(43, 54, 116, 1)",
              fontFamily: "DM Sans",
              fontWeight: 500,
              lineHeight: "14px",
              letterSpacing: "-0.32px",
            },
            "& .MuiOutlinedInput-input::placeholder": {
              color: "rgba(163, 174, 208, 1)",
              opacity: 1,
            },
            "& .MuiOutlinedInput-input:focus::placeholder": {
              opacity: 0,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
      </div>
      <ResetPasswordBtn onSubmit={onSubmit} />
      <BackToLoginBtn />
    </div>
  );
};
