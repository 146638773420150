import main1 from "../assets/fake/main/1.jpg";
import main2 from "../assets/fake/main/2.png";
import main3 from "../assets/fake/main/3.jpg";
import main4 from "../assets/fake/main/4.jpg";
import main5 from "../assets/fake/main/5.jpg";
import main6 from "../assets/fake/main/6.jpg";
import video from "../assets/fake/video/1.mp4";
import { Media } from "./convert";

export class RandomMediaGenerator {
  constructor(
    private imageList: Media[],
    private otherImagesList: Media[],
    private videoList: Media[]
  ) {
    this.imageList = imageList;
    this.otherImagesList = otherImagesList;
    this.videoList = videoList;
  }

  getMainByIndex(index: number): Media {
    return this.imageList[index];
  }

  getImageLen(): number {
    return this.imageList.length;
  }

  getRandomMain(): Media {
    return this.imageList[Math.floor(Math.random() * this.imageList.length)];
  }

  getRandomOther(): Media {
    return this.otherImagesList[
      Math.floor(Math.random() * this.otherImagesList.length)
    ];
  }

  getFakeVideo(): Media {
    return {
      url: this.videoList[0].url,
    };
  }
}

export const FAKE_MEDIA_MAIN: Media[] = [
  { url: main1 },
  { url: main2 },
  { url: main3 },
  { url: main4 },
  { url: main5 },
  { url: main6 },
];

export const FAKE_MEDIA_OTHER = FAKE_MEDIA_MAIN;

export const randomMediaGenerator = new RandomMediaGenerator(
  FAKE_MEDIA_MAIN,
  FAKE_MEDIA_MAIN,
  [{ url: video }]
);
