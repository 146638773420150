import { Property } from "./convert";

import GreenStampLtr from "../assets/greenStamp.png";
import GreenStampRtl from "../assets/GreenStampRtl.png";

import BlueStampLtr from "../assets/blueStamp.png";
import BlueStampRtl from "../assets/BlueStampRtl.png";

import OrangeStampLtr from "../assets/orangeStamp.png";
import OrangeStampRtl from "../assets/OrangeStampRtl.png";

import EcoIcon from "../assets/icons/eco.svg";
import HotIcon from "../assets/icons/hot.svg";
import PriceIcon from "../assets/icons/priceStamp.svg";

export const convertToPoints = (properties: Property[]) => {
  return properties.map((item) => ({
    id: item.id,
    latitude: item.location.coordinates.latitude,
    longitude: item.location.coordinates.longitude,
    title: item.name,
    basePrice: item.pricing.basePrice,
    location: {
      city: item.location.city,
      address: item.location.address,
      coordinates: {
        latitude: item.location.coordinates.latitude,
        longitude: item.location.coordinates.longitude

      }
    },
    mainImg: { url: item.mainImage.url },
  }));
};

export function formatPrice(price: number) {
  const priceInMillions = Math.floor(price / 1000000);
  return `${priceInMillions}M`;
}

export interface IStamp {
  name: string;
  imgLtr: string;
  imgRtl: string;
  icon: string;
}

const stamps = [
  {
    name: "eco_fiendly",
    imgLtr: GreenStampLtr,
    imgRtl: GreenStampRtl,
    icon: EcoIcon,
  },
  {
    name: "hot_price",
    imgLtr: BlueStampLtr,
    imgRtl: BlueStampRtl,

    icon: HotIcon,
  },
  {
    name: "sold",
    imgLtr: OrangeStampLtr,
    imgRtl: OrangeStampRtl,

    icon: PriceIcon,
  },
];

export const getRandomStamp = () => {
  const randomIndex = Math.floor(Math.random() * stamps.length);
  return stamps[randomIndex];
};
