import { TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setFilterSideBarClose } from "../../store/features/portfolioSlice";
import { useAppDispatch } from "../../store/store";

export const AskAITabPanelContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";

  const [inputValue, setInputValue] = React.useState<string>("");
  const [selectedHints, setSelectedHints] = React.useState<string[]>([]);

  const hints = [
    t("apartments_in_tel_aviv"),
    t("sea_view"),
    t("villas_with_garden"),
    t("two_storey_apartment"),
  ];

  const handleHintClick = (hint: string) => {
    if (selectedHints.includes(hint)) {
      setSelectedHints((prev) => prev.filter((h) => h !== hint));

      setInputValue((prev) =>
        prev
          .replace(new RegExp(`\\b${hint}\\b`, "g"), "")
          .replace(/\s+/g, " ")
          .trim()
      );
    } else {
      setSelectedHints((prev) => [...prev, hint]);
      setInputValue((prev) => (prev ? `${prev} ${hint}` : hint));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    const newSelectedHints = selectedHints.filter((hint) =>
      newValue.includes(hint)
    );
    setSelectedHints(newSelectedHints);
  };

  const handleSearch = () => {
    const params = new URLSearchParams();
    params.set("name", inputValue);
    dispatch(setFilterSideBarClose());
    navigate(`/portfolio?${params.toString()}`, { replace: true });
  };

  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          placeholder={t("ask_ai_placeholder")}
          multiline
          rows={6}
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "10px",
                height: "176px",
              },
            },
            "& .MuiInputBase-input::placeholder": {
              color: "rgba(145, 153, 174, 1)",
              opacity: 1,
              fontFamily: isRTL ? "Alef" : "DM Sans",
              fontWeight: isRTL ? 400 : 500,
              fontSize: "14px",
            },
            "& .MuiInputBase-input": {
              color: "#000",
              opacity: 1,
              fontFamily: isRTL ? "Alef" : "DM Sans",
              fontWeight: isRTL ? 400 : 500,
              fontSize: "14px",
            },
          }}
        />
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {hints.map((hint) => (
            <Typography
              key={hint}
              onClick={() => handleHintClick(hint)}
              sx={{
                padding: "8px 12px",
                borderRadius: "20px",
                fontSize: "14px",
                cursor: "pointer",
                border: selectedHints.includes(hint)
                  ? "1px solid rgb(103 116 121)"
                  : "1px solid rgba(184, 194, 198, 1)",

                color: "#000",
                fontWeight: 400,
                "&:hover": {
                  border: "1px solid rgb(103 116 121)",
                },
              }}
            >
              {hint}
            </Typography>
          ))}
        </div>
      </div>

      <button className="filter-side-bar-search-btn" onClick={handleSearch}>{t("search")}</button>
    </div>
  );
};
