import { useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const HeroImgStatisticsUpMd = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";

  return (
    <div
      className="hero-img-statistics-container"
      style={{
        direction: isRTL ? "rtl" : "ltr",
      }}
    >
      <div
        className="xl-width"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="hero-img-statistics-item"
          style={{
            paddingLeft: isRTL ? "0" : "20px",
            paddingRight: isRTL ? "20px" : " 0",
          }}
        >
          <div className="hero-img-statistics-quantity">10K+</div>
          <p
            className="hero-img-statistics-name"
            style={{
              textAlign: "left",
              fontFamily: isRTL ? "Alef" : "DM Sans",
              lineHeight: "28px",
            }}
          >
            {t("active")}
            <br />
            {t("investors")}
          </p>
        </div>

        <div className="hero-img-statistics-item">
          <div className="hero-img-statistics-quantity">30+</div>
          <p
            className="hero-img-statistics-name"
            style={{
              textAlign: "left",
              fontFamily: isRTL ? "Alef" : "DM Sans",
              lineHeight: "28px",
            }}
          >
            {t("successful")}
            <br />
            {t("projects")}
          </p>
        </div>

        <div
          className="hero-img-statistics-item"
          style={{
            paddingLeft: isRTL ? "20px" : "0",
            paddingRight: isRTL ? "0" : " 20px",
          }}
        >
          <div
            className="hero-img-statistics-quantity"
            style={{
              direction: "ltr",
            }}
          >
            {isRTL ? "₪" : "$"} 70M
          </div>
          <p
            className="hero-img-statistics-name"
            style={{
              textAlign: "left",
              fontFamily: isRTL ? "Alef" : "DM Sans",
              lineHeight: "28px",
            }}
          >
            {t("in_re")}
            <br />
            {t("sales")}
          </p>
        </div>
      </div>
    </div>
  );
};
