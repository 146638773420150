import { InputAdornment, TextField, Theme, useMediaQuery } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Arrow from "../../assets/icons/arrow.svg";
import logo from "../../assets/logo.png";

export const Footer = () => {
  const { t } = useTranslation(["home"]);
  const { i18n } = useTranslation();
  const isRTL = i18n.language === "he";
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <div className="footer-container xl-width">
      <div
        style={{
          boxShadow: "border-box",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: downMd ? "35px" : "65px",
          paddingBottom: downMd ? "35px" : "83px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: downMd ? "column" : isRTL ? "row-reverse" : "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <div
            className="footer-container-title"
            style={{
              marginBottom: "13px",
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("footer_title")}
          </div>
          <div
            className="footer-container-desc"
            style={{
              marginBottom: "25px",
              fontFamily: isRTL ? "Alef" : "DM Sans",
            }}
          >
            {t("footer_desc")}
          </div>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={t("enter_you_email")}
            sx={{
              width: downMd ? "100%" : "349px",
              backgroundColor: "rgba(3, 74, 115, 0.09)",
              border: "none",
              borderRadius: "27px",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
              "& .MuiInputBase-input::placeholder": {
                color: "rgba(0, 0, 0, 0.56)",
                opacity: 1,
                fontWeight: 400,
                fontFamily: isRTL ? "Alef" : "DM Sans",
              },
              "& .MuiInputBase-input": {
                color: "rgba(0, 0, 0, 0.56)",
                fontWeight: 400,
                fontFamily: isRTL ? "Alef" : "DM Sans",
                p: "20px 20px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    style={{
                      backgroundColor: "rgba(3, 74, 115, 1)",
                      width: "43px",
                      height: "43px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      src={Arrow}
                      alt="arrow"
                      style={{
                        transform: isRTL ? "scaleX(-1)" : " scaleX(1)",
                      }}
                    />
                  </div>
                </InputAdornment>
              ),
            }}
          />
          {!downMd && (
            <div
              style={{
                marginTop: "24px",
              }}
            >
              <img src={logo} style={{ width: "130px" }} />
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: downMd ? "30px" : "62px",
            direction: isRTL ? "rtl" : "ltr",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "59px",
              marginTop: downMd ? "30px" : "0",
              justifyContent: downMd ? "space-between" : "",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 700 : 500,
              }}
            >
              <div className="footer-container-nav-item">{t("about_us")}</div>
              <div className="footer-container-nav-item">{t("wishlist")}</div>
              <div className="footer-container-nav-item">{t("Properties")}</div>
              <div className="footer-container-nav-item">
                {t("sell_with_us")}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                fontFamily: isRTL ? "Alef" : "DM Sans",
                fontWeight: isRTL ? 700 : 500,
              }}
            >
              <div className="footer-container-nav-item">
                {t("privacy_policy")}
              </div>
              <div className="footer-container-nav-item">
                {t("term_of_use")}
              </div>
              <div className="footer-container-nav-item">
                {t("learning_center")}
              </div>
              <div className="footer-container-nav-item">{t("contact_us")}</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isRTL ? "row-reverse" : "row",
            }}
          >
            {downMd && (
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <img src={logo} style={{ width: "100px" }} />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <div
                style={{
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 0.62)",
                }}
              >
                {t("email_address")}
              </div>
              <div
                style={{
                  color: "rgba(0, 0, 0, 1)",
                  fontFamily: isRTL ? "Alef" : "DM Sans",
                  fontWeight: 500,
                }}
              >
                info@sentom.io
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
